import {BaseOptionRequest} from '../../api/baseOptionRequest';
import {Option} from '../../component/control/option';
import {AbstractEntity} from '../abstractEntity';
import {AttachmentModel} from '../attachment';

export enum ClientType {
    CLIENT = 1,
    CARRIER = 2,
    CLIENT_OR_CARRIER = 3,
    SUPPLIER = 4,
    CLIENT_OR_SUPPLIER = 5,
    ORGANIZATION = 6,
}

export enum ClientKind {
    LEGAL = 1,
    PHYSICAL = 2,
    SELF_EMPLOYED = 3,
    SEPARATE_DIVISION = 4,
    GOVERNMENT_UNIT = 5,
}

export type ClientOptionRequest = BaseOptionRequest & {
    clientTypes?: ClientType[];
    companyId?: number;
};

export type ClientManagerRequest = BaseOptionRequest & {
    clientId: number;
    role: string;
};

export type ClientContact = {
    clientAddress: Option;
    contact: Option;
};

export type ClientModel = AbstractEntity & {
    type: Option;
    kind: Option;
    company: Option;
    logisticManager: Option;
    commercialManager: Option;
    active: boolean;
    accredited: boolean;
    alien: boolean;
    currencyControl: boolean;
    name: string;
    fullName: string;
    inn: string;
    isoCode: Option;
    alienInn: boolean;
    kpp: string;
    ogrn: string;
    phone: string;
    email: string;
    web: string;
    postalAddress: string;
    actualAddress: string;
    address: string;
    head: string;
    headPosition: string;
    headGenitive: string;
    basis: Option;
    atiRating: string;
    atiCode: string;
    status: Option;
    comment: string;
    attachments: AttachmentModel[];
};
