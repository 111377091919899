import { deleteRequest, post, get, put } from './rest';

import type { PagedResponse } from './pagedResponse';
import type { ListRequest } from '../component/table/listRequest';
import type { ScalesListModel } from '../model/scales/scalesListModel';
import type { ApiResponse } from './apiResponse';
import type { ScalesModel } from '../model/scales/scalesModel';
import type { BaseOptionRequest } from './baseOptionRequest';
import type { Option } from '../component/control/option';
import type { ExternalScalesRequest } from '../model/scales/externalScalesRequest';

const PATH_BASE = 'scales';

const scales = {
    list: (model: ListRequest) => post<PagedResponse<ScalesListModel>>(`${PATH_BASE}/list`, model),
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),
    get: (id: number) => get<ScalesModel>(`${PATH_BASE}/${id}`),
    save: (model: ScalesModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
    options: (request: BaseOptionRequest) => post<Option[]>(`${PATH_BASE}/options`, request),
    fetchExternalWeight: (request: ExternalScalesRequest) =>
        post<ApiResponse>(`${PATH_BASE}/fetch-weight`, request),
};

export default scales;
