import React, { MouseEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import util from '../../../common/util';
import { Config } from '../../table/config';
import { RemoveButton } from '../../table/RemoveButton';
import { FormContext, useFieldValue, useSetFieldValue, validateField } from '../../form/DwForm';
import { useIsMounted } from '../../../common/isMounted';
import { EditableDwTable } from '../../table/EditableDwTable';
import { PalletPassportListModel } from '../model/talman/palletPassportModel';
import { PalletPassportField } from './palletPassportField';
import { TalmanModel } from '../model/talman/talmanModel';
import { TalmanField } from './talmanField';
import docs from '../../../api/docs';
import { FileType } from '../../../model/fileType';
import clsx from 'clsx';
import fileUtil from '../../../common/fileUtil';
import { MimeType } from '../../../model/mimeType';
import Scales from '../../form/Scales';
import { RootContext } from '../../layout/ContextWrapper';
import scales from '../../../api/scales';
import { handleApiError } from '../../../api/apiResponse';

import type { SingleValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../control/option';

type Props = {
    id: string;
};

const onDownload = (e: MouseEvent, id: number) => {
    e.preventDefault();
    return docs.palletPassport(id, FileType.PDF)
        .then(blob => fileUtil.downloadBlob(blob, `Паспорт_паллеты_${id}.${FileType.PDF}`));
};

const onPrint = (e: MouseEvent, id: number) => {
    e.preventDefault();
    return docs.palletPassport(id, FileType.PDF)
        .then(blob => fileUtil.printBlob(blob, MimeType.PDF));
};

const onChangeRow = (
    newValue: any,
    rowNum: number,
    row: PalletPassportListModel,
    field: PalletPassportField,
    palletPassports: PalletPassportListModel[],
    onChange: (palletPassports: PalletPassportListModel[]) => void
): Promise<void> => {
    return new Promise<void>((resolve) => {
        if (rowNum === 0) {
            const newPalletPassports = [...palletPassports];
            newPalletPassports
                .filter(pp => !pp[field] || pp[field] === row[field])
                .forEach(pp => {
                    pp[field] = newValue;
                });
            onChange(newPalletPassports);
        }
        resolve();
    });
};

const useFormConfig = (
    id: string,
    palletPassportsRemaining: number,
    palletPassports: PalletPassportListModel[],
    onChange: (palletPassports: PalletPassportListModel[]) => void,
    onAdd: (e: React.UIEvent<HTMLElement> | React.KeyboardEvent) => void,
    onDelete: (e: MouseEvent, row: PalletPassportListModel, index: number) => void,
    handleScalesChange: (newValue: SingleValue<Option>) => void,
    handleExternalWeight: (e: MouseEvent, row: PalletPassportListModel) => void
) =>
    useMemo(
        () =>
            Config.builder<PalletPassportListModel>()
                .column((builder) => builder.position('#').cls('id'))
                .column((builder) => builder.integer('Количество мест', PalletPassportField.BOXES).editable().cls('number'))
                .column((builder) => builder.decimal('Фактический вес брутто, кг', PalletPassportField.GROSS_WEIGHT).editable().cls('number'))
                .column((builder) =>
                    builder
                        .decimal('Вес пустой коробки', PalletPassportField.BOX_WEIGHT)
                        .editable()
                        .onChangeRow((newValue, rowNum, row) =>
                            onChangeRow(newValue, rowNum, row, PalletPassportField.BOX_WEIGHT, palletPassports, onChange))
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .decimal('Вес пустого поддона + скрепл. материалов', PalletPassportField.PALLET_WEIGHT)
                        .editable()
                        .onChangeRow((newValue, rowNum, row) =>
                            onChangeRow(newValue, rowNum, row, PalletPassportField.PALLET_WEIGHT, palletPassports, onChange))
                        .cls('number')
                )
                .column((builder) => builder.label('Фактический вес нетто, кг').getter(calcNetWeight).cls('number'))
                .column((builder) =>
                    builder
                        .label('Действия')
                        .getter((model) => {
                            if (model.id > 0) {
                                return (
                                    <>
                                        <button
                                            className='btn btn-xs btn-primary'
                                            title='Получить вес'
                                            onClick={(e) => handleExternalWeight(e, model)}
                                        >
                                            <i className='icon fa fa-balance-scale' />
                                        </button>
                                        &nbsp;
                                        <button
                                            className='btn btn-xs btn-primary'
                                            title='Напечатать документ'
                                            onClick={(e) => onPrint(e, model.id)}
                                        >
                                            <i className='icon fa fa-print' />
                                        </button>
                                        &nbsp;
                                        <button
                                            className='btn btn-xs btn-primary'
                                            title='Скачать документ'
                                            onClick={(e) => onDownload(e, model.id)}
                                        >
                                            <i className='icon fa fa-download' />
                                        </button>
                                    </>
                                );
                            }
                            return <></>;
                        })
                        .cls('text-center actions')
                        .width('20px')
                )
                .column((builder) =>
                    builder
                        .getter((model, index) => (
                            <RemoveButton onClick={(e: MouseEvent) => onDelete(e, model, index!)}/>
                        ))
                        .cls('text-center actions')
                        .width('20px')
                )
                .id(id)
                .captionControl(() => (
                    <div className='d-flex flex-row'>
                        <div className='mr-4'>
                            <Scales id={TalmanField.SCALES} onChange={handleScalesChange} />
                        </div>
                        &nbsp;
                        <button
                            className={clsx('btn btn-outline-primary btn-sm', {
                                disabled: palletPassportsRemaining <= 0,
                            })}
                            onClick={(e) =>
                                palletPassportsRemaining > 0 ? onAdd(e) : e.preventDefault()
                            }
                        >
                            добавить
                        </button>
                    </div>
                ))
                .build(),
        [
            id,
            palletPassportsRemaining,
            palletPassports,
            onChange,
            onAdd,
            onDelete,
            handleScalesChange,
            handleExternalWeight,
        ]
    );

const calcNetWeight = (model: PalletPassportListModel) => {
    const { grossWeight, boxes, boxWeight, palletWeight } = model;
    if (grossWeight > 0) {
        let netWeight = grossWeight;
        if (boxes > 0 && boxWeight > 0) {
            netWeight -= boxes * boxWeight;
        }
        if (palletWeight > 0) {
            netWeight -= palletWeight;
        }
        return netWeight > 0 ? parseFloat(netWeight.toFixed(2)) : 0;
    }
    return 0;
};

const calcTotalNetWeight = (palletPassports: PalletPassportListModel[]) => palletPassports.reduce(
    (total, passport) => total + Number(calcNetWeight(passport)), 0
).toFixed(2);

export const PalletPassportTable: React.FC<Props> = ({id}) => {
    const context = useContext(FormContext);
    const model = context.state.model as TalmanModel;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const palletPassportsDataValue = fieldConfig.getter(model);
    const palletPassportsData: PalletPassportListModel[] = useMemo(() => palletPassportsDataValue ?? [], [palletPassportsDataValue]);
    const {
        state: { uiState },
        setUiState,
    } = useContext(RootContext);

    const clientAssortmentBoxes = useFieldValue(TalmanField.CLIENT_ASSORTMENT_BOXES);
    const maxPalletPassports = useFieldValue(TalmanField.MAX_PALLET_PASSPORTS);
    const palletPassportsRemaining = useMemo(() => (maxPalletPassports ?? 0) - palletPassportsData.length, [palletPassportsData, maxPalletPassports]);
    const currentScales = useFieldValue(TalmanField.SCALES);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [palletPassportsData]);

    useEffect(() => {
        if (isMounted && uiState.currentScales?.value > 0) {
            const { currentScales } = uiState;
            if (model[TalmanField.SCALES] !== currentScales) {
                setValue(TalmanField.SCALES, currentScales);
            }
        }
    }, [isMounted, setValue, uiState, model]);

    const onChange = useCallback((palletPassports: PalletPassportListModel[]) => setValue(id, palletPassports), [id, setValue]);

    const onDelete = (e: MouseEvent, row: PalletPassportListModel, index: number) => {
        e.preventDefault();
        const palletPassports = [...palletPassportsData];
        palletPassports.splice(index, 1);
        onChange(palletPassports);
    };

    const onAdd = useCallback((e: React.UIEvent<HTMLElement> | React.KeyboardEvent): void => {
        e.preventDefault();
        const { boxWeight: defaultBoxWeight, palletWeight: defaultPalletWeight } = palletPassportsData[0] || {};
        const palletPassport: Partial<PalletPassportListModel> = {
            boxes: clientAssortmentBoxes,
            boxWeight: defaultBoxWeight,
            palletWeight: defaultPalletWeight,
        };
        onChange([...palletPassportsData, palletPassport as PalletPassportListModel]);
    }, [palletPassportsData, onChange, clientAssortmentBoxes]);

    const handleScalesChange = useCallback(
        (newValue: SingleValue<Option>) => {
            if (newValue) {
                setUiState({ ...uiState, currentScales: newValue });
            }
        },
        [setUiState, uiState]
    );

    const handleExternalScales = useCallback(
        async (e: MouseEvent, row: PalletPassportListModel) => {
            e.preventDefault();

            if (!currentScales?.value || currentScales.value < 1) {
                return alert('Весы не выбраны');
            }

            const { uri, token } = await scales.get(currentScales.value);
            return await scales.fetchExternalWeight({ uri, token }).then(({ message, error }) => {
                if (error) {
                    return handleApiError(error);
                }

                const updatedRow = { ...row, grossWeight: Number(message) };
                const updatedPalletPassports = palletPassportsData.map((item) =>
                    item.id === row.id ? updatedRow : item
                );
                onChange(updatedPalletPassports);
            });
        },
        [currentScales?.value, onChange, palletPassportsData]
    );

    const config = useFormConfig(
        id,
        palletPassportsRemaining,
        palletPassportsData,
        onChange,
        onAdd,
        onDelete,
        handleScalesChange,
        handleExternalScales
    );

    return (
        <div className='pallet-passports'>
            <EditableDwTable config={config} header={'Паспорта паллет'}>
                <tr className={'summary-row'}>
                    <td className='text-center'>Итого</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.BOXES)}</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.GROSS_WEIGHT, 2)}</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.BOX_WEIGHT, 2)}</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.PALLET_WEIGHT, 2)}</td>
                    <td>{parseFloat(calcTotalNetWeight(palletPassportsData))}</td>
                    <td />
                </tr>
            </EditableDwTable>
        </div>
    );
};
