import React from 'react';
import { generatePath, Outlet } from 'react-router-dom';
import palletPassportTemplates from '../../../api/palletPassportTemplate';
import util from '../../../common/util';
import {
    PATH_CP_PALLET_PASSPORT_TEMPLATES,
    PATH_VARIABLE_PALLET_PASSPORT_TEMPLATE_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import { DwForm, useFieldValue } from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import { FormConfig } from '../../form/formConfig';
import { PalletPassportTemplateModel } from '../../../model/palletPassportTemplate/palletPassportTemplateModel';
import { PalletPassportTemplateField } from './palletPassportTemplateField';

const formConfig = FormConfig.builder<PalletPassportTemplateModel>()
    .number(
        PalletPassportTemplateField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .requiredText(
        PalletPassportTemplateField.NAME,
        (m) => m.name,
        (m, v) => (m.name = v)
    )
    .requiredText(
        PalletPassportTemplateField.HTML_CONTENT,
        (m) => m.htmlContent,
        (m, v) => (m.htmlContent = v)
    )
    .load((id) => palletPassportTemplates.get(id))
    .submit(palletPassportTemplates.save)
    .redirectUrl(PATH_CP_PALLET_PASSPORT_TEMPLATES)
    .idPathVariableName(PATH_VARIABLE_PALLET_PASSPORT_TEMPLATE_ID)
    .build();

const PalletPassportTemplateForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <PalletPassportTemplateLayout />
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const PalletPassportTemplateLayout: React.FC = () => {
    const id = useFieldValue(PalletPassportTemplateField.ID);
    const currentName = useFieldValue(PalletPassportTemplateField.NAME);
    const isNew: boolean = !id;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новый шаблон' : `Шаблон ${currentName}`,
                        path: generatePath(PATH_CP_PALLET_PASSPORT_TEMPLATES, {
                            [PATH_VARIABLE_PALLET_PASSPORT_TEMPLATE_ID]: util.stringOrEmpty(id),
                        }),
                        end: true,
                    }                    
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default PalletPassportTemplateForm;
