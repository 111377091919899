import React from 'react';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import StringInput from '../../form/StringInput';
import { PalletPassportTemplateField } from './palletPassportTemplateField';
import TextInput from '../../form/TextInput';
import { useFieldValue } from '../../form/DwForm';

const PalletPassportTemplate: React.FC = () => {
    const id = useFieldValue(PalletPassportTemplateField.ID);
    const isDefault = id === 1;
    return (
        <>
            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-3'>
                            Наименование
                            <StringInput
                                id={PalletPassportTemplateField.NAME}
                                readOnly={isDefault}
                            />
                        </div>
                    </div>
                    <div className='row'>                    
                        <div className='col-12'>
                            Шаблон
                            <TextInput 
                                id={PalletPassportTemplateField.HTML_CONTENT} 
                                rows={25}
                                readOnly={isDefault}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <SaveAndCancelButtonGroup/>
            </div>
        </>
    );
};

export default PalletPassportTemplate;
