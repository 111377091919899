import type { AbstractEntity } from '../abstractEntity';
import { Option } from '../../component/control/option';

export type PalletPassportTemplateListModel = AbstractEntity & {
    name: string;
};

export type PalletPassportTemplateModel = AbstractEntity & {
    name: string;
    htmlContent: string;
};

export const DEFAULT_TEMPLATE_OPTION: Option = {
    value: 1,
    label: 'Паспорт паллеты по умолчанию',
};