import React from 'react';
import { Outlet } from 'react-router';
import { generatePath } from 'react-router-dom';
import scales from '../../../api/scales';
import util from '../../../common/util';
import {
    PATH_CP_SCALES,
    PATH_CP_SCALES_HISTORY,
    PATH_CP_SCALES_LIST,
    PATH_VARIABLE_SCALES_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import { DwForm, useFieldValue } from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import { FormConfig } from '../../form/formConfig';
import { ScalesField } from './scalesField';

import type { ScalesModel } from '../../../model/scales/scalesModel';

const formConfig = FormConfig.builder<ScalesModel>()
    .number(
        ScalesField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .requiredText(
        ScalesField.NAME,
        (m) => m.name,
        (m, v) => (m.name = v)
    )
    .requiredOption(
        ScalesField.STORAGE,
        (m) => m.storage,
        (m, v) => (m.storage = v)
    )
    .requiredText(
        ScalesField.URI,
        (m) => m.uri,
        (m, v) => (m.uri = v)
    )
    .requiredText(
        ScalesField.TOKEN,
        (m) => m.token,
        (m, v) => (m.token = v)
    )
    .load((id) => scales.get(id))
    .submit(scales.save)
    .redirectUrl(PATH_CP_SCALES_LIST)
    .idPathVariableName(PATH_VARIABLE_SCALES_ID)
    .build();

const ScalesForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <ScalesLayout />
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const ScalesLayout: React.FC = () => {
    const scalesId = useFieldValue(ScalesField.ID);
    const scalesName = useFieldValue(ScalesField.NAME);
    const isNew = !scalesId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новые Весы' : scalesName,
                        path: generatePath(PATH_CP_SCALES, {
                            [PATH_VARIABLE_SCALES_ID]: util.stringOrEmpty(scalesId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_SCALES_HISTORY, {
                            [PATH_VARIABLE_SCALES_ID]: util.stringOrEmpty(scalesId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />

            <ContextError />
            <Outlet />
        </>
    );
};

export default ScalesForm;
