export class ApiResponse {
    error: string = '';
    message: string = '';
    success: () => boolean = () => !!this.error;
}

enum BackendApiErrors {
    FILE_NOT_FOUND = 'File not found',
    INTERNAL_SERVER_ERROR = 'Server error',
    SQL_REFERENCE_ERROR = 'There are some references in other entities',
    DATA_ACCESS_ERROR = 'Data access error',
    BAD_REQUEST_ERROR = 'Bad request or missing required parameters',
    NOT_FOUND_ERROR = 'Resource not found',
    SERVICE_UNAVAILABLE_ERROR = 'Remote service unavailable',
    UNAUTHORIZED = 'Unauthorized',
    TIMEOUT_ERROR = 'Request timed out',
}

enum ClientApiErrors {
    FILE_NOT_FOUND = 'Файл не найден',
    INTERNAL_SERVER_ERROR = 'Внутренняя ошибка сервера',
    SQL_REFERENCE_ERROR = 'Есть связи в других каталогах',
    DATA_ACCESS_ERROR = 'Ошибка доступа к данным',
    BAD_REQUEST_ERROR = 'Некорректный запрос или отсутствуют обязательные параметры',
    NOT_FOUND_ERROR = 'Ресурс не найден',
    SERVICE_UNAVAILABLE_ERROR = 'Удалённый сервис недоступен',
    UNAUTHORIZED = 'Неверный или просроченный токен',
    TIMEOUT_ERROR = 'Время запроса истекло',
}

const API_ERRORS = new Map<string, string>([
    [BackendApiErrors.FILE_NOT_FOUND, ClientApiErrors.FILE_NOT_FOUND],
    [BackendApiErrors.INTERNAL_SERVER_ERROR, ClientApiErrors.INTERNAL_SERVER_ERROR],
    [BackendApiErrors.SQL_REFERENCE_ERROR, ClientApiErrors.SQL_REFERENCE_ERROR],
    [BackendApiErrors.DATA_ACCESS_ERROR, ClientApiErrors.DATA_ACCESS_ERROR],
    [BackendApiErrors.BAD_REQUEST_ERROR, ClientApiErrors.BAD_REQUEST_ERROR],
    [BackendApiErrors.NOT_FOUND_ERROR, ClientApiErrors.NOT_FOUND_ERROR],
    [BackendApiErrors.SERVICE_UNAVAILABLE_ERROR, ClientApiErrors.SERVICE_UNAVAILABLE_ERROR],
    [BackendApiErrors.UNAUTHORIZED, ClientApiErrors.UNAUTHORIZED],
    [BackendApiErrors.TIMEOUT_ERROR, ClientApiErrors.TIMEOUT_ERROR],
]);

export const handleApiError = (apiError: string) => {
    const defaultMessage = ClientApiErrors.INTERNAL_SERVER_ERROR;
    const errorMessage: string = API_ERRORS.get(apiError) || defaultMessage;

    return window.alert(errorMessage);
};
