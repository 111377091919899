export enum TalmanField {
    QUOTE_ID = 'quoteId',
    QUOTE_NUMBER = 'quoteNumber',
    UNLOADING_STORAGE = 'unloadingStorage',
    TARE_TMC = 'tareTmc',
    SEAL = 'seal',
    INSPECTION_PLACE = 'inspectionPlace',
    INSPECTION_DATETIME = 'inspectionDatetime',
    VEHICLES_INFO = 'vehiclesInfo',
    LOADING_DATETIME = 'loadingDatetime',
    ASSORTMENT = 'assortment',
    PALLET_PASSPORTS = 'palletPassports',
    MAX_PALLET_PASSPORTS = 'maxPalletPassports',
    TARE_QUANTITY = 'tareQuantity',
    TARE_DISTRIBUTED = 'tareDistributed',
    CLIENT_ASSORTMENT_BOXES = 'clientAssortmentBoxes',
    CLIENT = 'client',
    SCALES = 'scales',
}
