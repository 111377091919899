import React from 'react';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../control/option';
import palletPassportTemplates from '../../../../api/palletPassportTemplate';
import FormSelect from '../../../form/FormSelect';
import { DEFAULT_TEMPLATE_OPTION } from '../../../../model/palletPassportTemplate/palletPassportTemplateModel';

type Props = {
    id: string;
    onChange?: (option: SingleValue<Option>) => void;
};

export const PalletPassportTemplateSelect = ({ id, onChange }: Props) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        palletPassportTemplates.allOptions({
            filter: inputValue,
        }).then((options) => callback(options));
    };

    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            defaultValue={DEFAULT_TEMPLATE_OPTION}
        />
    );
}