import React from 'react';
import { generatePath } from 'react-router-dom';
import scales from '../../../api/scales';
import { PATH_CP_SCALES, PATH_VARIABLE_SCALES_ID } from '../../../routerPaths';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { ScalesField } from './scalesField';

import type { ScalesListModel } from '../../../model/scales/scalesListModel';

const ScalesList: React.FC = () => {
    const config = Config.builder<ScalesListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.text('Название', ScalesField.NAME))
        .column((builder) =>
            builder.text('Склад производства', ScalesField.STORAGE).sortable(false)
        )
        .column((builder) =>
            builder
                .cls('text-center')
                .sortable(false)
                .filterable(false)
                .getter((model, _, refresh) => (
                    <RemoveButton
                        onClick={() => onRowDelete(refresh, () => scales.delete(model.id))}
                    />
                ))
                .width('45px')
        )
        .formUrl(generatePath(PATH_CP_SCALES, { [PATH_VARIABLE_SCALES_ID]: '' }))
        .load((ctx) => scales.list(ctx.state.request))
        .id('scales')
        .defaultHidden([])
        .build();

    return (
        <div className='scales d-flex'>
            <DwTable header='Весы' config={config} />
        </div>
    );
};

export default ScalesList;
