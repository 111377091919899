import React from 'react';
import {Tab} from '../form/DwFormTabs';
import {Column, ColumnBuilder} from './column';
import {Context, State} from './context';
import Cache from '../../common/cache';
import {CACHE_KEY} from './DwTable';
import {PagedResponse} from '../../api/pagedResponse';
import {createSearchParams, NavigateFunction} from 'react-router-dom';
import {PATH_BASE} from '../../routerPaths';

class Config<T> {
    static TableBuilder = class<T> {
        private table = new Config<T>();

        column<R>(build: (builder: ColumnBuilder<T, R>) => void): this {
            const columnBuilder = new ColumnBuilder<T, R>();
            build(columnBuilder);
            const column = columnBuilder.build();
            if (column.position >= 0) {
                this.table.columns.splice(column.position, 0, column);
            } else {
                this.table.columns.push(column);
            }
            return this;
        }

        formUrl(url: string) {
            this.table.formUrl = url;
            return this;
        }

        searchParams(searchParams: (m: T) => URLSearchParams) {
            this.table.searchParamsGetter = searchParams;
            return this;
        }

        build() {
            return this.table;
        }

        load(fn: (ctx: Context) => Promise<PagedResponse<T>>) {
            this.table.load = fn;
            return this;
        }

        id(value: string) {
            this.table.id = value;
            return this;
        }

        defaultHidden(value: string[]) {
            this.table.defaultHidden = value;
            return this;
        }

        columnsSelector(value: boolean) {
            this.table.columnsSelector = value;
            return this;
        }

        editable(value: boolean) {
            this.table.editable = value;
            return this;
        }

        filterable(value: boolean) {
            this.table.columns.forEach(it => it.filterable = value);
            return this;
        }

        sortable(value: boolean) {
            this.table.columns.forEach(it => it.sortable = value);
            return this;
        }

        onClick(value: (id: number, navigate: NavigateFunction, model: T, ctx: Context) => void) {
            this.table.onClick = value;
            return this;
        }

        outerFilter<R>(build: (builder: ColumnBuilder<T, R>) => void) {
            const columnBuilder = new ColumnBuilder<T, R>();
            build(columnBuilder);
            this.table.outerFilters.push(columnBuilder.build());
            return this;
        }

        captionControl(fn: (ctx: Context) => React.ReactElement) {
            this.table.captionControls.push(fn);
            return this;
        }

        withAdd(val: boolean) {
            this.table.withAdd = val;
            return this;
        }

        withSearch(val: boolean) {
            this.table.withSearch = val;
            return this;
        }

        tabs(tabs: Tab[]) {
            this.table.tabs = tabs;
            return this;
        }

        rowClassNameFn(fn: (row: T) => string) {
            this.table.rowClassNameFn = fn;
            return this;
        }
    };

    id: string = '';
    columns: Column<T, any>[] = [];
    defaultHidden: string[] = [];
    formUrl: string = PATH_BASE;
    columnsSelector: boolean = true;
    editable: boolean = false;
    outerFilters: Column<T, any>[] = [];
    captionControls: ((_: Context) => React.ReactElement)[] = [];
    withAdd: boolean = true;
    tabs: Tab[] = [];
    withSearch: boolean = true;

    public static builder<T>() {
        return new Config.TableBuilder<T>();
    }

    searchParamsGetter: (m: T) => URLSearchParams = (_) => new URLSearchParams();

    rowClassNameFn: (model: T) => string = (_) => "";

    load: (_: Context) => Promise<PagedResponse<T>> = () => Promise.resolve({} as PagedResponse<T>);

    onClick: (id: number, navigate: NavigateFunction, model: T, ctx: Context) => void = (id, navigate, model, ctx) => {
        navigate({
            pathname: `${this.formUrl}${id && id > 0 ? '/' + id : ''}`,
            search: createSearchParams(this.searchParamsGetter(model)).toString(),
        });
    };

    public orderedColumns(): Column<T, any>[] {
        const state: State | null = Cache.findInStorage(CACHE_KEY + this.id);
        return this.columns.length === state?.order?.length
            ? state.order.map((it) => this.columns[it])
            : this.columns;
    }

    public orderedVisibleColumns(hidden: string[]): Column<T, any>[] {
        return this.orderedColumns().filter((it) => !hidden.includes(it.field));
    }
}

export {Config};
