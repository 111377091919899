import React from 'react';
import { Storages } from '../../form/ClientStorages';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import StringInput from '../../form/StringInput';
import { ScalesField } from './scalesField';

const Scales: React.FC = () => {
    return (
        <div className='card h-100'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-lg-3'>
                        Название
                        <StringInput id={ScalesField.NAME} />
                    </div>

                    <div className='col-lg-2'>
                        Склад производства
                        <Storages id={ScalesField.STORAGE} />
                    </div>
                </div>

                <div className='row align-items-center'>
                    <hr className='col-1 mx-2' />
                    <div className='col-auto'>API настройка весов</div>
                    <hr className='col mx-2' />
                </div>

                <div className='row'>
                    <div className='col-lg-4'>
                        URI
                        <StringInput id={ScalesField.URI} />
                    </div>

                    <div className='col-lg-3'>
                        Токен
                        <StringInput id={ScalesField.TOKEN} />
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

export default Scales;
