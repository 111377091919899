export enum AuditSource {
    QUOTE = 'quote',
    RUN = 'run',
    CLIENT = 'client',
    CRM_USER = 'crm_user',
    STORAGE = 'client_address',
    VEHICLE = 'vehicle',
    TMC = 'tmc',
    ASSORTMENT = 'assortment',
    PRODUCTION_LINE = 'production_line',
    ASSORTMENT_OPERATION = 'assortment_operation',
    TMC_OPERATION = 'tmc_operation',
    SCALES = 'scales',
}
