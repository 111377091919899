import {generatePath} from 'react-router-dom';
import {Role} from '../../model/role';
import {DictionaryNames} from '../control/option';
import {
    EXTERNAL_LINK_BOOKSTACK,
    EXTERNAL_LINK_SUPERSET,
    PATH_CP,
    PATH_CP_ASSORTMENTS,
    PATH_CP_CLIENTS,
    PATH_CP_COMPANIES,
    PATH_CP_DICTIONARY,
    PATH_CP_DICTIONARY_LIST,
    PATH_CP_DRIVERS,
    PATH_CP_INTEGRATIONS,
    PATH_CP_INTEGRATIONS_DADATA,
    PATH_CP_INTEGRATIONS_EDI,
    PATH_CP_PRODUCTION_LINES,
    PATH_CP_PALLET_PASSPORT_TEMPLATES,
    PATH_CP_STORAGES,
    PATH_CP_TMCS,
    PATH_CP_USERS,
    PATH_CP_VEHICLES,
    PATH_EDI_MESSAGES,
    PATH_KPP,
    PATH_QUOTES,
    PATH_RUNS,
    PATH_STORAGE,
    PATH_STORAGE_ASSORTMENT_OPERATIONS,
    PATH_STORAGE_QUOTES,
    PATH_STORAGE_TMC_OPERATIONS,
    PATH_STORAGE_LOADING,
    PATH_CP_INTEGRATIONS_EDI_SETTINGS,
    PATH_CP_INTEGRATIONS_EDI_PARTY,
    PATH_CP_STORAGE_CHARTS,
    PATH_CP_STORAGE_STATE_CHART,
    PATH_CP_SCALES_LIST,
} from '../../routerPaths';
import util from '../../common/util';

type NavItem = {
    path: string;
    label: string;
    icon: string;
    roles: Role[];
    items: NavItem[];
    targetBlank: boolean;
};

const defaults = {
    icon: '',
    roles: [Role.ADMIN],
    items: [],
    targetBlank: false,
};

const dictionaryItems: NavItem[] = Array.from(DictionaryNames.entries()).map((it) => {
    const [key, value] = it;
    return {
        ...defaults,
        path: generatePath(PATH_CP_DICTIONARY_LIST, {dictionaryType: util.stringOrEmpty(key)}),
        label: value,
    } as NavItem;
});

const dictionaries: NavItem = {
    ...defaults,
    label: 'Справочники',
    path: PATH_CP_DICTIONARY,
    items: [...dictionaryItems],
};

const edi: NavItem = {
    ...defaults,
    label: 'EDI',
    path: PATH_CP_INTEGRATIONS_EDI,
    items: [
        { ...defaults, label: 'Настройки', path: PATH_CP_INTEGRATIONS_EDI_SETTINGS },
        { ...defaults, label: 'Торговые сети', path: PATH_CP_INTEGRATIONS_EDI_PARTY },
    ]
};

const integrations: NavItem = {
    ...defaults,
    label: 'Интеграции',
    path: PATH_CP_INTEGRATIONS,
    items: [
        {...defaults, label: 'DaData', path: PATH_CP_INTEGRATIONS_DADATA},
        {...edi},
        {...defaults, label: 'Весы', path: PATH_CP_SCALES_LIST}
    ],
};

const storageCharts: NavItem = {
    ...defaults,
    label: 'Отчеты',
    path: PATH_CP_STORAGE_CHARTS,
    items: [{ ...defaults, label: 'Состояние склада', path: PATH_CP_STORAGE_STATE_CHART }],
};

const items: NavItem[] = [
    {...defaults, label: 'Заказы', path: `${PATH_QUOTES}?domestic=false`, icon: 'chart-area'},
    {...defaults, label: 'Заказы EDI', path: PATH_EDI_MESSAGES, icon: 'chart-area'},
    {...defaults, label: 'Рейсы', path: `${PATH_RUNS}?domestic=false`, icon: 'chart-area'},
    {...defaults, label: 'АРМ КПП', path: PATH_KPP, icon: 'chart-area'},
    {
        ...defaults,
        label: 'Отчеты',
        path: EXTERNAL_LINK_SUPERSET,
        icon: 'chart-bar',
        targetBlank: true,
    },
    {
        ...defaults,
        label: 'База знаний',
        path: EXTERNAL_LINK_BOOKSTACK,
        icon: 'book',
        targetBlank: true,
    },
    {
        ...defaults,
        label: 'Склад',
        path: PATH_STORAGE,
        icon: 'warehouse',
        items: [
            {...defaults, label: 'Заказы склада', path: PATH_STORAGE_QUOTES},
            {
                ...defaults,
                label: 'Погрузка',
                path: PATH_STORAGE_LOADING,
            },
            {...defaults, label: 'Операции Номенклатуры', path: PATH_STORAGE_ASSORTMENT_OPERATIONS},
            {...defaults, label: 'Операции ТМЦ', path: PATH_STORAGE_TMC_OPERATIONS},
            storageCharts,
        ]
    },
    {
        ...defaults,
        label: 'Панель Управления',
        path: PATH_CP,
        icon: 'wrench',
        items: [
            {...defaults, path: PATH_CP_CLIENTS, label: 'Контрагенты'},
            {...defaults, path: PATH_CP_USERS, label: 'Пользователи'},
            {...defaults, path: PATH_CP_COMPANIES, label: 'Организации'},
            {...defaults, path: PATH_CP_STORAGES, label: 'Склады'},
            {...defaults, path: PATH_CP_VEHICLES, label: 'Транспорт'},
            {...defaults, path: PATH_CP_DRIVERS, label: 'Водители'},
            {...defaults, path: PATH_CP_TMCS, label: 'ТМЦ'},
            {...defaults, path: PATH_CP_ASSORTMENTS, label: 'Номенклатура'},
            {...defaults, path: PATH_CP_PRODUCTION_LINES, label: 'Производственные линии'},
            {...defaults, path: PATH_CP_PALLET_PASSPORT_TEMPLATES, label: 'Печатные формы паспортов паллет'},
            dictionaries,
            integrations,
        ],
    },
];

export {items};
export type {NavItem};
