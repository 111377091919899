import React from 'react';
import scales from '../../api/scales';
import FormSelect from './FormSelect';

import type { Option } from '../control/option';

type Props = {
    id: string;
    onChange?: (newValue: any) => void;
};

const Scales: React.FC<Props> = ({ id, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        scales.options({ filter: inputValue }).then((options) => callback(options));
    };

    return <FormSelect id={id} loadOptions={loadOptions} onChange={onChange} />;
};

export default Scales;
