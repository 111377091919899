import React, {Fragment, MouseEvent, useCallback, useContext, useEffect, useMemo} from 'react';
import {createSearchParams, generatePath, useLocation} from 'react-router-dom';
import options from '../../api/options';
import {RUN_STATUS, RunStatus} from '../../model/enums/RunStatus';
import {RunType, RunTypeNames} from '../../model/enums/RunType';
import {DictionaryOptionsRequest} from '../control-panel/dictionary/dictionaryOptionsRequest';
import ActiveDrivers from '../form/ActiveDrivers';
import CheckboxInput from '../form/CheckboxInput';
import Clients from '../form/Clients';
import ClientContacts from '../form/ClientContacts';
import Companies from '../form/Companies';
import ComponentLink, {ComponentLinkType} from '../form/ComponentLink';
import Contracts from '../form/Contracts';
import DtPicker from '../form/DtPicker';
import {DwForm, FormContext, useFieldValue, useIsLoaded, useSetFieldValue} from '../form/DwForm';
import {FormConfig} from '../form/formConfig';
import {QuoteModel} from '../quotes/model/quoteModel';
import Expenses from './expenses/Expenses';
import NumberInput from '../form/NumberInput';
import Options from '../form/Options';
import Route from './route/Route';
import StringInput from '../form/StringInput';
import TextInput from '../form/TextInput';
import Users from '../form/Users';
import Vehicles from '../form/Vehicles';
import {SingleValue} from 'react-select/dist/declarations/src/types';
import {RunPointModel} from './model/runPointModel';
import {DictionaryType, NULL_OPTION, Option} from '../control/option';
import clients from '../../api/clients';
import contracts from '../../api/contracts';
import docs from '../../api/docs';
import quotes from '../../api/quotes';
import runs from '../../api/runs';
import route from '../../common/route';
import util from '../../common/util';
import {
    expensesValidator,
    notNullablePositiveIntByStatusValidator,
    requiredByDomesticRunValidator,
    requiredByStatusValidator,
    routeValidator,
} from '../../common/validation/runValidators';
import {nullableOrPositiveIntValidator} from '../../common/validation/simpleValidators';
import {ClientType} from '../../model/client/clientModel';
import {ContractModel} from '../../model/contract/contractModel';
import {ContractRequest} from '../../model/contract/contractRequest';
import {CARRIER_CONTRACT_TYPES, ContractType} from '../../model/enums/ContractType';
import {VehicleType} from '../../model/enums/VehicleType';
import {FileType} from '../../model/fileType';
import {Role} from '../../model/role';
import {PATH_QUOTE, PATH_RUNS, PATH_VARIABLE_RUN_ID} from '../../routerPaths';
import {Context} from '../form/context';
import ContextError from '../form/ContextError';
import {RunExpensesModel} from './model/runExpensesModel';
import {RunModel} from './model/runModel';
import {CARRIER_CONTRACT_DEPENDENT_FIELDS, CLIENT_CONTRACT_DEPENDENT_FIELDS, MockRunField, RunField,} from './runField';
import {RunFormContext} from './RunForm';
import onChangeContract from '../quotes/model/onChangeContract';
import SaveAndCancelButtonGroup from '../form/SaveAndCancelButtonGroup';
import {ProxyModel} from '../../model/proxyModel';
import routeUtil from './route/routeUtil';
import {useIsMounted} from '../../common/isMounted';
import fileUtil from '../../common/fileUtil';

export type LocationStateRun = {
    sourceId?: number; // to load values by source run id
    quoteOption?: Option; // to create run points based on passed quote
};

const useFormConfig = (run: RunModel, quoteId?: number) =>
    useMemo(
        () =>
            FormConfig.builder<RunModel>()
                .number(
                    RunField.ID,
                    (m) => m.id,
                    (m, v) => (m.id = v)
                )
                .dateTime(
                    RunField.CREATED,
                    (m) => m.created,
                    (m, v) => (m.created = v)
                )
                .requiredOption(
                    RunField.STATUS,
                    (m) => m.status,
                    (m, v) => (m.status = v)
                )
                .option(
                    RunField.TYPE,
                    (m) => m.type,
                    (m, v) => (m.type = v),
                    (m) => requiredByStatusValidator(m.type, m.status)
                )
                .text(
                    RunField.NUMBER,
                    (m) => m.number,
                    (m, v) => (m.number = v)
                )
                .text(
                    RunField.DESCRIPTION,
                    (m) => m.description,
                    (m, v) => (m.description = v)
                )

                .option(
                    RunField.TTN_STATUS,
                    (m) => m.ttnStatus,
                    (m, v) => (m.ttnStatus = v)
                )
                .text(
                    RunField.TTN_NUMBER,
                    (m) => m.ttnNumber,
                    (m, v) => (m.ttnNumber = v)
                )
                .dateTime(
                    RunField.TTN_DATE,
                    (m) => m.ttnDate,
                    (m, v) => (m.ttnDate = v)
                )
                .text(
                    RunField.PROXY_NUMBER,
                    (m) => m.proxyNumber,
                    (m, v) => (m.proxyNumber = v)
                )
                .dateTime(
                    RunField.PROXY_DATE,
                    (m) => m.proxyDate,
                    (m, v) => (m.proxyDate = v)
                )

                .option(
                    RunField.LOGISTIC_MANAGER,
                    (m) => m.logisticManager,
                    (m, v) => (m.logisticManager = v)
                )
                .option(
                    RunField.COMMERCIAL_MANAGER,
                    (m) => m.commercialManager,
                    (m, v) => (m.commercialManager = v)
                )

                .requiredOption(
                    RunField.CLIENT_COMPANY,
                    (m) => m.clientCompany,
                    (m, v) => (m.clientCompany = v)
                )
                .option(
                    RunField.CLIENT,
                    (m) => m.client,
                    (m, v) => (m.client = v),
                    (m) => requiredByDomesticRunValidator(m.client, m.isDomesticRun)
                )
                .option(
                    RunField.CLIENT_CONTACT,
                    (m) => m.clientContact,
                    (m, v) => (m.clientContact = v)
                )
                .option(
                    RunField.CLIENT_CONTRACT,
                    (m) => m.clientContract,
                    (m, v) => (m.clientContract = v),
                    (m) => requiredByStatusValidator(m.clientContract, m.status)
                )
                .number(
                    RunField.CLIENT_PRICE,
                    (m) => m.clientPrice?.value!,
                    (m, v) => (m.clientPrice = {value: v}),
                    (m) => requiredByStatusValidator(m.clientPrice, m.status)
                )
                .option(
                    RunField.CLIENT_PAYMENT_METHOD,
                    (m) => m.clientPaymentMethod,
                    (m, v) => (m.clientPaymentMethod = v),
                    (m) => requiredByStatusValidator(m.clientPaymentMethod, m.status)
                )
                .option(
                    RunField.CLIENT_PAYMENT_TYPE,
                    (m) => m.clientPaymentType,
                    (m, v) => (m.clientPaymentType = v),
                    (m) => requiredByStatusValidator(m.clientPaymentType, m.status)
                )
                .number(
                    RunField.CLIENT_PAYMENT_DELAY,
                    (m) => m.clientPaymentDelay?.value!,
                    (m, v) => (m.clientPaymentDelay = {value: v}),
                    (m) => notNullablePositiveIntByStatusValidator(m.clientPaymentDelay?.value, m.status)
                )
                .dateTime(
                    RunField.CLIENT_PAYMENT_DATE,
                    (m) => m.clientPaymentDate?.value!,
                    (m, v) => (m.clientPaymentDate = {value: v})
                )
                .dateTime(
                    RunField.CLIENT_PLANNED_PAYMENT_DATE,
                    (m) => m.clientPlannedPaymentDate?.value!,
                    (m, v) => (m.clientPlannedPaymentDate = {value: v})
                )
                .list<RunExpensesModel>(
                    RunField.CLIENT_EXPENSES,
                    (m) => m.clientExpenses,
                    (m, v) => (m.clientExpenses = v),
                    (m) => expensesValidator(m.clientExpenses)
                )
                .option(
                    RunField.CLIENT_PAYMENT_DELAY_TYPE,
                    (m) => m.clientPaymentDelayType,
                    (m, v) => (m.clientPaymentDelayType = v)
                )

                .requiredOption(
                    RunField.CARRIER_COMPANY,
                    (m) => m.carrierCompany,
                    (m, v) => (m.carrierCompany = v)
                )
                .option(
                    RunField.CARRIER,
                    (m) => m.carrier,
                    (m, v) => (m.carrier = v)
                )
                .option(
                    RunField.CARRIER_CONTACT,
                    (m) => m.carrierContact,
                    (m, v) => (m.carrierContact = v)
                )
                .option(
                    RunField.CARRIER_CONTRACT,
                    (m) => m.carrierContract,
                    (m, v) => (m.carrierContract = v)
                )
                .number(
                    RunField.CARRIER_PRICE,
                    (m) => m.carrierPrice?.value!,
                    (m, v) => (m.carrierPrice = {value: v}),
                    (m) => requiredByStatusValidator(m.carrierPrice, m.status)
                )
                .option(
                    RunField.CARRIER_PAYMENT_METHOD,
                    (m) => m.carrierPaymentMethod,
                    (m, v) => (m.carrierPaymentMethod = v)
                )
                .option(
                    RunField.CARRIER_PAYMENT_TYPE,
                    (m) => m.carrierPaymentType,
                    (m, v) => (m.carrierPaymentType = v)
                )
                .number(
                    RunField.CARRIER_PAYMENT_DELAY,
                    (m) => m.carrierPaymentDelay?.value!,
                    (m, v) => (m.carrierPaymentDelay = {value: v}),
                    (m) => nullableOrPositiveIntValidator(m.carrierPaymentDelay?.value)
                )
                .dateTime(
                    RunField.CARRIER_PAYMENT_DATE,
                    (m) => m.carrierPaymentDate?.value!,
                    (m, v) => (m.carrierPaymentDate = {value: v})
                )
                .dateTime(
                    RunField.CARRIER_PLANNED_PAYMENT_DATE,
                    (m) => m.carrierPlannedPaymentDate?.value!,
                    (m, v) => (m.carrierPlannedPaymentDate = {value: v})
                )
                .list<RunExpensesModel>(
                    RunField.CARRIER_EXPENSES,
                    (m) => m.carrierExpenses,
                    (m, v) => (m.carrierExpenses = v),
                    (m) => expensesValidator(m.carrierExpenses)
                )
                .option(
                    RunField.CARRIER_PAYMENT_DELAY_TYPE,
                    (m) => m.carrierPaymentDelayType,
                    (m, v) => (m.carrierPaymentDelayType = v)
                )

                .option(
                    RunField.DRIVER,
                    (m) => m.driver,
                    (m, v) => (m.driver = v),
                    (m) => requiredByStatusValidator(m.driver, m.status)
                )
                .option(
                    RunField.TRUCK,
                    (m) => m.truck,
                    (m, v) => (m.truck = v),
                    (m) => requiredByStatusValidator(m.truck, m.status)
                )
                .option(
                    RunField.TRAILER,
                    (m) => m.trailer,
                    (m, v) => (m.trailer = v)
                )

                .list<RunPointModel>(
                    RunField.ROUTE,
                    (m) => m.route,
                    (m, v) => (m.route = v),
                )
                .proxyList<RunPointModel>(
                    RunField.GROUPED_SORTED_ROUTE,
                    (m) => routeUtil.group(m.route),
                    (a: ProxyModel<RunPointModel>, b: ProxyModel<RunPointModel>) => routeUtil.sort(a, b),
                    (m, v) => (m.route = v.flatMap(el => el.refs)),
                    (m) => routeValidator(m)
                )

                .boolean(
                    RunField.IS_DOMESTIC_RUN,
                    (m) => m.isDomesticRun,
                    (m, v) => (m.isDomesticRun = v)
                )

                .load((_) => Promise.resolve(run))
                .submit(runs.save)
                .idPathVariableName(PATH_VARIABLE_RUN_ID)
                .redirectUrl(
                    quoteId && quoteId > 0
                        ? generatePath(PATH_QUOTE, {quoteId: util.toString(quoteId)})
                        : PATH_RUNS
                )
                .searchParams((m) =>
                    createSearchParams({
                        domestic: m.isDomesticRun ? 'true' : 'false',
                    })
                )
                .build(),
        [run, quoteId]
    );

const Run: React.FC = () => {
    const {run} = useContext(RunFormContext);
    const location = useLocation();
    const {quoteOption} = (location.state || {}) as LocationStateRun;
    const formConfig = useFormConfig(run, quoteOption?.value);
    return (
        <DwForm config={formConfig}>
            <RunInner/>
            <ContextError/>
        </DwForm>
    );
};

const RunInner: React.FC = () => {
    const context = useContext(FormContext);
    const {
        state: {model, config: formConfig},
    } = context as Context<RunModel>;
    const setValue = useSetFieldValue();
    const isFormLoaded = useIsLoaded();
    const location = useLocation();
    const {quoteOption} = (location.state || {}) as LocationStateRun;
    const isMounted = useIsMounted();

    const valueId = useFieldValue(RunField.ID);
    const valueClientCompany = useFieldValue(RunField.CLIENT_COMPANY);
    const valueClient = useFieldValue(RunField.CLIENT);
    const valueClientContact = useFieldValue(RunField.CLIENT_CONTACT);
    const valueClientContract = useFieldValue(RunField.CLIENT_CONTRACT);
    const valueCarrierCompany = useFieldValue(RunField.CARRIER_COMPANY);
    const valueCarrier = useFieldValue(RunField.CARRIER);
    const valueCarrierContact = useFieldValue(RunField.CARRIER_CONTACT);
    const valueCarrierContract = useFieldValue(RunField.CARRIER_CONTRACT);
    const valueCreated = useFieldValue(RunField.CREATED);
    const valueCommercialManager = useFieldValue(RunField.COMMERCIAL_MANAGER);
    const valueRouteRaw = useFieldValue(RunField.ROUTE) as RunPointModel[];
    const valueRoute = useMemo(() => valueRouteRaw ?? [], [valueRouteRaw]);
    const valueClientDelayType = useFieldValue(RunField.CLIENT_PAYMENT_DELAY_TYPE);
    const valueCarrierDelayType = useFieldValue(RunField.CARRIER_PAYMENT_DELAY_TYPE);
    const valueIsDomesticRun = useFieldValue(RunField.IS_DOMESTIC_RUN);

    const valueDriver = useFieldValue(RunField.DRIVER);
    const valueTruck = useFieldValue(RunField.TRUCK);
    const valueTrailer = useFieldValue(RunField.TRAILER);

    const getUnloadingPoints = (): RunPointModel[] => valueRoute.filter((rp) => !rp.isLoading);
    const getLastUnloadingPoint = (): RunPointModel => routeUtil.findLastUnloading(valueRoute) ?? {} as RunPointModel;

    const setDefaultMainContract = (request: ContractRequest, fieldMap: Map<string, string>) => {
        contracts
            .getDefaultMain(request)
            .then((contract) => updateFieldsFromContract(contract, fieldMap));
    };

    const setContract = useCallback(
        (contract: Option, client: Option, fieldMap: Map<string, string>) => {
            const contractId = contract?.value;
            const clientId = client?.value;
            if (contractId && clientId) {
                contracts
                    .getForClient(contractId, clientId)
                    .then((contract) => updateFieldsFromContract(contract, fieldMap));
            }
        },
        [setValue, model, formConfig]
    );

    const updateFieldsFromContract = (contract: ContractModel, fieldMap: Map<string, string>) => {
        const {id, number, paymentMethod, paymentType, paymentDelay, delayType} = contract;

        setValue(fieldMap.get(MockRunField.CONTRACT)!, id > 0 ? {value: id, label: number} as Option : NULL_OPTION);
        setValue(fieldMap.get(MockRunField.PAYMENT_METHOD)!, paymentMethod ?? NULL_OPTION);
        setValue(fieldMap.get(MockRunField.PAYMENT_TYPE)!, paymentType ?? NULL_OPTION);
        setValue(fieldMap.get(MockRunField.PAYMENT_DELAY)!, paymentDelay);
        setValue(fieldMap.get(MockRunField.PAYMENT_DELAY_TYPE)!, delayType ?? NULL_OPTION);
        updatePlannedPaymentDatetime(
            getLastUnloadingPoint()?.date,
            paymentDelay,
            delayType,
            fieldMap
        );
    };

    const updatePlannedPaymentDatetime = (
        unloadingDate: Date,
        paymentDelay: number,
        delayType: Option,
        fieldMap: Map<string, string>
    ) => {
        if (unloadingDate && delayType?.value > 0) {
            const plannedPaymentDt = util.calculatePlannedPaymentDate(
                unloadingDate,
                paymentDelay,
                delayType.value
            );
            setValue(fieldMap.get(MockRunField.PLANNED_PAYMENT_DATETIME)!, plannedPaymentDt);
        }
    };

    const onChangeClientCompany = useCallback(
        (newValue: SingleValue<Option>) => {
            setValue(RunField.CLIENT_CONTRACT, {});
        },
        [setValue]
    );

    const setContact = useCallback(
        (clientId: number, dependentField: RunField) => {
            clients
                .contact(clientId)
                .then((res) =>
                    res.contact?.value > 0
                        ? setValue(dependentField, res.contact)
                        : setValue(dependentField, {})
                );
        },
        [setValue]
    );

    const setManagerFromClient = useCallback(
        (clientId: number, role: Role, dependentField: RunField) => {
            clients
                .findClientManager({
                    filter: '',
                    clientId,
                    role,
                })
                .then((manager) => manager?.value > 0 && setValue(dependentField, manager));
        },
        [setValue]
    );

    const onChangeClient = useCallback(
        (newValue: SingleValue<Option>) => {
            // clear all the fields except company
            setValue(RunField.CLIENT, NULL_OPTION);
            setValue(RunField.CLIENT_CONTACT, NULL_OPTION);
            setValue(RunField.CLIENT_CONTRACT, NULL_OPTION);
            setValue(RunField.CLIENT_PAYMENT_METHOD, NULL_OPTION);
            setValue(RunField.CLIENT_PRICE, null);
            setValue(RunField.CLIENT_PAYMENT_TYPE, NULL_OPTION);
            setValue(RunField.CLIENT_PAYMENT_DELAY, null);
            setValue(RunField.CLIENT_PAYMENT_DATE, null);
            setValue(RunField.CLIENT_PLANNED_PAYMENT_DATE, null);
            if (newValue && valueClientCompany) {
                setDefaultMainContract(
                    {
                        clientId: newValue.value,
                        clientTypeId: ClientType.CLIENT,
                        companyId: valueClientCompany.value,
                    },
                    CLIENT_CONTRACT_DEPENDENT_FIELDS
                );
            }

            if (newValue && isFormLoaded) {
                setManagerFromClient(newValue.value, Role.COMMERCIAL, RunField.COMMERCIAL_MANAGER);
                setContact(newValue?.value, RunField.CLIENT_CONTACT);
            }
        },
        [
            isFormLoaded,
            setContact,
            setDefaultMainContract,
            setManagerFromClient,
            setValue,
            valueClientCompany,
        ]
    );

    const fetchQuote = useCallback(async (quoteOption: Option) => {
        return await quotes.get(quoteOption.value);
    }, []);

    const updateRunFieldsByQuote = useCallback(
        (quote: QuoteModel) => {
            setValue(RunField.CLIENT, quote.client);
            setValue(RunField.CLIENT_COMPANY, quote.clientCompany);
            setValue(RunField.COMMERCIAL_MANAGER, quote.manager);
            setValue(RunField.CARRIER_COMPANY, quote.clientCompany);
            setValue(RunField.CLIENT_CONTACT, quote.clientContact);
            setValue(RunField.CLIENT_CONTRACT, quote.clientContract);
            setValue(RunField.CLIENT_PAYMENT_METHOD, quote.clientPaymentMethod);
            setValue(RunField.CLIENT_PAYMENT_DELAY, quote.clientPaymentDelay);
            setValue(RunField.CLIENT_PAYMENT_DELAY_TYPE, quote.clientPaymentDelayType);
            setValue(RunField.CLIENT_PAYMENT_TYPE, quote.clientPaymentType);
            setValue(RunField.CLIENT_PAYMENT_DATE, quote.clientPaymentDatetime);
            setValue(RunField.CLIENT_PLANNED_PAYMENT_DATE, quote.clientPlannedPaymentDate);
            setValue(RunField.IS_DOMESTIC_RUN, quote.isDomesticQuote);
        },
        [setValue]
    );

    const onDomesticCheckboxChange = useCallback(
        (newValue: boolean) => {
            if (newValue) {
                const domesticRunType = {
                    value: RunType.DOMESTIC,
                    label: RunTypeNames.get(RunType.DOMESTIC),
                };
                setValue(RunField.TYPE, domesticRunType);
            } else {
                setValue(RunField.TYPE, {value: -1});
            }
        },
        [setValue]
    );

    useEffect(() => {
        const quoteId = quoteOption?.value;
        if (!quoteId || quoteId <= 0 || !isMounted) return;

        const isQuoteUniqueForRoute = routeUtil.isQuoteUniqueForRoute(valueRoute, quoteId);
        const hasClient = valueClient?.value > 0;
        if (!isQuoteUniqueForRoute && hasClient) return;

        fetchQuote(quoteOption).then(quote => {
            // to create from quote / add to quote
            if (isQuoteUniqueForRoute) {
                const loadingPoint = routeUtil.createLoadingQuotePoint(quote);
                const unloadingPoint = routeUtil.createUnloadingQuotePoint(quote);
                setValue(RunField.ROUTE, [...valueRoute, loadingPoint, unloadingPoint]);
            }

            if (!hasClient) {
                updateRunFieldsByQuote(quote);
            }
        });
    }, [isMounted, valueId, quoteOption, fetchQuote]);

    useEffect(() => {
        if (isMounted && !model.status) {
            (async () => {
                const preliminaryStatus = await options
                    .options({
                        filter: RUN_STATUS.get(RunStatus.PRELIMINARY),
                        dictionaryType: DictionaryType.RUN_STATUS,
                    } as DictionaryOptionsRequest)
                    .then((optionList) => optionList[0]);

                setValue(RunField.STATUS, preliminaryStatus);
            })();
        }
    }, [isMounted, model.status, setValue]);

    const renderClient = () => {
        return (
            <div className='card h-100'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col'></div>
                        <div className='col-auto'>
                            <div className='custom-control custom-switch'>
                                <CheckboxInput
                                    id={RunField.IS_DOMESTIC_RUN}
                                    label='Внутренний рейс'
                                    disabled={valueClientCompany?.value && valueClient?.value}
                                    onChange={onDomesticCheckboxChange}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            Организация для Заказчика
                            <Companies
                                id={RunField.CLIENT_COMPANY}
                                onChange={onChangeClientCompany}
                            />
                        </div>
                    </div>

                    {!valueIsDomesticRun ? (
                        <>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4'>
                                    Заказчик&nbsp;
                                    <ComponentLink
                                        type={ComponentLinkType.CLIENT}
                                        entityIdChain={[valueClient?.value]}
                                    />
                                    <Clients
                                        id={RunField.CLIENT}
                                        clientTypes={[
                                            ClientType.CLIENT,
                                            ClientType.CLIENT_OR_CARRIER,
                                            ClientType.CLIENT_OR_SUPPLIER,
                                        ]}
                                        companyId={valueClientCompany?.value}
                                        onChange={onChangeClient}
                                        readOnly={model.id > 0 && valueRoute?.length > 0}
                                        nullable
                                    />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    Договор&nbsp;
                                    <ComponentLink
                                        type={ComponentLinkType.CLIENT_CONTRACT}
                                        entityIdChain={[
                                            valueClient?.value,
                                            valueClientContract?.value,
                                        ]}
                                    />
                                    <Contracts
                                        id={RunField.CLIENT_CONTRACT}
                                        clientId={valueClient?.value}
                                        companyId={valueClientCompany?.value}
                                        contractTypes={[ContractType.CLIENT, ContractType.SUPPLIER]}
                                        onChange={(newValue) =>
                                            onChangeContract({
                                                newValue,
                                                valueType: ContractType.CLIENT,
                                                setContract,
                                                valueClient,
                                            })
                                        }
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    Контактное лицо&nbsp;
                                    <ComponentLink
                                        type={ComponentLinkType.CLIENT_CONTACT}
                                        entityIdChain={[
                                            valueClient?.value,
                                            valueClientContact?.value,
                                        ]}
                                    />
                                    <ClientContacts
                                        id={RunField.CLIENT_CONTACT}
                                        clientId={valueClient?.value}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4'>
                                    Ставка заказчика
                                    <NumberInput id={RunField.CLIENT_PRICE}/>
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    Форма оплаты
                                    <Options
                                        id={RunField.CLIENT_PAYMENT_METHOD}
                                        dictionaryType={DictionaryType.PAYMENT_METHOD}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    Условия оплаты
                                    <Options
                                        id={RunField.CLIENT_PAYMENT_TYPE}
                                        dictionaryType={DictionaryType.PAYMENT_TYPE}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4'>
                                    Доп. расходы
                                    <Expenses id={RunField.CLIENT_EXPENSES}/>
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    Отсрочка платежа
                                    <NumberInput
                                        id={RunField.CLIENT_PAYMENT_DELAY}
                                        onChange={(paymentDelay) => {
                                            updatePlannedPaymentDatetime(
                                                getLastUnloadingPoint()?.date,
                                                paymentDelay,
                                                valueClientDelayType,
                                                CLIENT_CONTRACT_DEPENDENT_FIELDS
                                            );
                                        }}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    Стоимость груза
                                    <div className='form-group'>
                                        {util.sumOverObjectProps(
                                            getUnloadingPoints(),
                                            'cargoPrice',
                                            2
                                        ) ?? 0}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4'>
                                    Фактическая дата оплаты
                                    <DtPicker id={RunField.CLIENT_PAYMENT_DATE}/>
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    Плановая дата оплаты
                                    <DtPicker id={RunField.CLIENT_PLANNED_PAYMENT_DATE}/>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                Доп. расходы
                                <Expenses id={RunField.CLIENT_EXPENSES}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const onChangeCarrierCompany = useCallback(
        (newValue: SingleValue<Option>) => {
            setValue(RunField.CARRIER_CONTRACT, {});
        },
        [setValue]
    );

    const onChangeCarrier = useCallback(
        (newValue: SingleValue<Option>) => {
            // clear all the fields except company
            setValue(RunField.CARRIER, NULL_OPTION);
            setValue(RunField.CARRIER_CONTACT, NULL_OPTION);
            setValue(RunField.CARRIER_CONTRACT, NULL_OPTION);
            setValue(RunField.CARRIER_PAYMENT_METHOD, NULL_OPTION);
            setValue(RunField.CARRIER_PRICE, null);
            setValue(RunField.CARRIER_PAYMENT_TYPE, NULL_OPTION);
            setValue(RunField.CARRIER_PAYMENT_DELAY, null);
            setValue(RunField.CARRIER_PAYMENT_DATE, null);
            setValue(RunField.CARRIER_PLANNED_PAYMENT_DATE, null);
            setValue(RunField.CARRIER_PAYMENT_DELAY_TYPE, NULL_OPTION);
            setValue(RunField.LOGISTIC_MANAGER, NULL_OPTION);
            setValue(RunField.DRIVER, NULL_OPTION);
            setValue(RunField.TRUCK, NULL_OPTION);
            setValue(RunField.TRAILER, NULL_OPTION);
            if (newValue && valueCarrierCompany) {
                setDefaultMainContract(
                    {
                        clientId: newValue.value,
                        clientTypeId: ClientType.CARRIER,
                        companyId: valueCarrierCompany.value,
                    },
                    CARRIER_CONTRACT_DEPENDENT_FIELDS
                );
            }

            if (newValue && isFormLoaded) {
                setManagerFromClient(newValue.value, Role.LOGISTICIAN, RunField.LOGISTIC_MANAGER);
                setContact(newValue?.value, RunField.CARRIER_CONTACT);
            }
        },
        [
            isFormLoaded,
            setContact,
            setDefaultMainContract,
            setManagerFromClient,
            setValue,
            valueCarrierCompany,
        ]
    );

    const onChangeDriver = (newValue: SingleValue<Option>) => {
        const driverId = newValue?.value;
        if (driverId) {
            runs.lastVehiclesByDriver(driverId).then((response) => {
                setValue(RunField.TRUCK, response.truck ?? NULL_OPTION);
                setValue(RunField.TRAILER, response.trailer);
            });
        }
    };

    const renderCarrier = () => {
        return (
            <div className='card h-100'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            Организация для Перевозчика
                            <Companies
                                id={RunField.CARRIER_COMPANY}
                                onChange={onChangeCarrierCompany}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Перевозчик&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT}
                                entityIdChain={[valueCarrier?.value]}
                            />
                            <Clients
                                id={RunField.CARRIER}
                                clientTypes={[ClientType.CARRIER, ClientType.CLIENT_OR_CARRIER]}
                                companyId={valueCarrierCompany?.value}
                                onChange={onChangeCarrier}
                                nullable
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Договор
                            <Contracts
                                id={RunField.CARRIER_CONTRACT}
                                clientId={valueCarrier?.value}
                                companyId={valueCarrierCompany?.value}
                                contractTypes={CARRIER_CONTRACT_TYPES}
                                onChange={(newValue) =>
                                    onChangeContract({
                                        newValue,
                                        valueType: ContractType.CARRIER,
                                        setContract,
                                        valueCarrier,
                                    })
                                }
                            />
                        </div>
                        <div className='col-lg-4'>
                            Контактное лицо&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT_CONTACT}
                                entityIdChain={[
                                    valueCarrier?.value,
                                    valueCarrierContact?.value,
                                ]}
                            />
                            <ClientContacts
                                id={RunField.CARRIER_CONTACT}
                                clientId={valueCarrier?.value}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Ставка перевозчика
                            <NumberInput id={RunField.CARRIER_PRICE}/>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Форма оплаты
                            <Options
                                id={RunField.CARRIER_PAYMENT_METHOD}
                                dictionaryType={DictionaryType.PAYMENT_METHOD}
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Условия оплаты
                            <Options
                                id={RunField.CARRIER_PAYMENT_TYPE}
                                dictionaryType={DictionaryType.PAYMENT_TYPE}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3'>
                            Доп. расходы
                            <Expenses id={RunField.CARRIER_EXPENSES}/>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            Отсрочка платежа
                            <NumberInput
                                id={RunField.CARRIER_PAYMENT_DELAY}
                                onChange={(paymentDelay) => {
                                    updatePlannedPaymentDatetime(
                                        getLastUnloadingPoint()?.date,
                                        paymentDelay,
                                        valueCarrierDelayType,
                                        CARRIER_CONTRACT_DEPENDENT_FIELDS
                                    );
                                }}
                            />
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            Плановая дата оплаты
                            <DtPicker id={RunField.CARRIER_PLANNED_PAYMENT_DATE}/>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            Фактическая дата оплаты
                            <DtPicker id={RunField.CARRIER_PAYMENT_DATE}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Водитель
                            <ComponentLink
                                type={ComponentLinkType.DRIVER}
                                entityIdChain={[valueDriver?.value]}
                            />
                            <ActiveDrivers
                                id={RunField.DRIVER}
                                clientId={valueCarrier?.value}
                                onChange={onChangeDriver}
                                nullable
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Тягач
                            <ComponentLink
                                type={ComponentLinkType.VEHICLE}
                                entityIdChain={[valueTruck?.value]}
                            />
                            <Vehicles
                                id={RunField.TRUCK}
                                clientId={valueCarrier?.value}
                                vehicleTypes={[VehicleType.TRUCK, VehicleType.VAN, VehicleType.BUS]}
                                nullable
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Полуприцеп
                            <ComponentLink
                                type={ComponentLinkType.VEHICLE}
                                entityIdChain={[valueTrailer?.value]}
                            />
                            <Vehicles
                                id={RunField.TRAILER}
                                clientId={valueCarrier?.value}
                                vehicleTypes={[VehicleType.TRAILER]}
                                nullable
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Доставка
                            <Options id={RunField.TYPE} dictionaryType={DictionaryType.RUN_TYPE}/>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            {valueIsDomesticRun && <Fragment>
                                Дата доверенности
                                <DtPicker id={RunField.PROXY_DATE}/>
                            </Fragment>}
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Менеджер ОЛ
                            <Users id={RunField.LOGISTIC_MANAGER} role={Role.LOGISTICIAN} nullable/>
                        </div>
                    </div>
                    {valueIsDomesticRun && <div className='row'>
                        <div className='col-lg-4 col-md-4'/>
                        <div className='col-lg-4 col-md-4'>
                            Номер доверенности
                            <StringInput id={RunField.PROXY_NUMBER}/>
                        </div>
                        <div className='col-lg-4 col-md-4'/>
                    </div>}
                </div>
            </div>
        );
    };

    const doNothing = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    const downloadCarrierAgreementPdf = (e: MouseEvent) => {
        e.preventDefault();
        const carrierId = valueCarrier?.value;
        const carrierCompanyId = valueCarrierCompany?.value;
        if (carrierId && carrierId > 0 && carrierCompanyId && carrierCompanyId > 0) {
            const carrierContractId = valueCarrierContract?.value;
            if (carrierContractId && carrierContractId > 0) {
                contracts
                    .isForClientAndCompany(carrierContractId, carrierId, carrierCompanyId)
                    .then((isForClientAndCompany) => {
                        if (isForClientAndCompany) {
                            docs.runCarrierTransportationAgreement(valueId, FileType.PDF).then((blob) =>
                                fileUtil.downloadBlob(blob, `Заявка_к_договору_с_Перевозчиком_по_рейсу_${valueId}.${FileType.PDF}`));
                        } else {
                            route.pushError('Выбранный договор заключен с другой организацией.');
                        }
                    });
            } else {
                docs.runCarrierAgreement(valueId, FileType.PDF).then((blob) =>
                    fileUtil.downloadBlob(blob, `Договор-заявка_с_Перевозчиком_по_рейсу_${valueId}.${FileType.PDF}`));
            }
        } else {
            route.pushError('Выберите Перевозчика и Организацию для Перевозчика. Сохраните.');
        }
    };

    return (
        <div>
            <div className='row mb-3 no-gutters'>
                <div className='col mr-2'>{renderClient()}</div>
                <div className='col ml-2'>{renderCarrier()}</div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-12'>
                    <Route id={RunField.ROUTE} proxyId={RunField.GROUPED_SORTED_ROUTE}/>
                </div>
            </div>

            <div className='row mb-3 mx-3 d-flex align-items-center'>
                <div className='col-lg-1 col-md-4 col-sm-6'>
                    Номер
                    <StringInput id={RunField.NUMBER}/>
                </div>
                <div className='col-lg-1 col-md-4 col-sm-6'>
                    Дата рейса:
                    <div className='form-group'>{util.formatDate(valueCreated ?? new Date())}</div>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-6'>
                    Статус рейса
                    <Options id={RunField.STATUS} dictionaryType={DictionaryType.RUN_STATUS}/>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-6'>
                    Статус ТТН
                    <Options id={RunField.TTN_STATUS} dictionaryType={DictionaryType.TTN_STATUS}/>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-6'>
                    Номер ТТН
                    <StringInput id={RunField.TTN_NUMBER}/>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-6'>
                    Дата ТТН
                    <DtPicker id={RunField.TTN_DATE}/>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-6'>
                    Менеджер КО
                    <div className='form-group'>{valueCommercialManager?.label}</div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <TextInput id={RunField.DESCRIPTION}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-3 text-left'>
                    <button className='btn btn-sm btn-primary disabled' onClick={doNothing}>
                        Заявка Заказчика
                    </button>
                </div>
                <div className='col-lg-6 text-center'>
                    <button className='btn btn-sm btn-outline-primary disabled' onClick={doNothing}>
                        <i className='fas fa-plus mr-1'/>
                        Задачу
                    </button>
                    &nbsp;
                    <SaveAndCancelButtonGroup/>
                </div>
                <div className='col-lg-3 text-right'>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={downloadCarrierAgreementPdf}
                    >
                        Заявка Перевозчика
                    </button>
                    &nbsp;
                    <button className='btn btn-sm btn-primary disabled' onClick={doNothing}>
                        Документы
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Run;
