import React from 'react';
import {DOMESTIC} from '../../common/const';
import { DwTable } from '../table/DwTable';
import {createSearchParams, generatePath, useSearchParams} from 'react-router-dom';
import {PATH_RUN, PATH_RUNS, PATH_VARIABLE_RUN_ID} from '../../routerPaths';
import runs from '../../api/runs';
import './css/runs.scss';
import CopyButton from '../table/CopyButton';
import { ActionType } from '../table/action';
import { ColumnBuilder } from '../table/column';
import docs from '../../api/docs';
import { RunModel } from './model/runModel';
import { baseConfigBuilder, ROW_SELECT_MOCK_FIELD } from './runsBaseConfigBuilder';
import { RunField } from './runField';
import { ColumnType } from '../table/columnType';
import { RowSelector } from '../table/RowSelector';
import { RunStatus } from '../../model/enums/RunStatus';
import fileUtil from '../../common/fileUtil';
import { FileType } from '../../model/fileType';

const ROW_SELECT_COLUMN_POSITION = 0;
const STATUS_COLUMN_POSITION = 3;

const Runs: React.FC = () => {
    const [searchParams] = useSearchParams();
    const domesticParam = searchParams.get(DOMESTIC)!;
    const config = baseConfigBuilder()
        .column((builder) =>
            builder
                .field(ROW_SELECT_MOCK_FIELD)
                .getter((model) => <RowSelector id={model.id} />)
                .sortable(false)
                .filterable(false)
                .width('30px')
                .cls('text-center')
                .columnPosition(ROW_SELECT_COLUMN_POSITION)
        )
        .column((builder) =>
            builder
                .label('Статус')
                .field(RunField.STATUS)
                .type(ColumnType.RUN_STATUS)
                .cls('status')
                .columnPosition(STATUS_COLUMN_POSITION)
                .editable()
                .onChangeRow(
                    (value, idx, row) => runs.save({ id: row.id, status: value } as RunModel)
                )
        )
        .column((builder) =>
            builder
                .integer('Разгрузок', RunField.UNLOADING_POINT_COUNT)
                .filterable(false)
                .sortable(false)
                .cls('text-center')
        )
        .column((builder) =>
            builder
                .label('Копировать рейс')
                .getter((model) => (
                    <CopyButton
                        id={model.id}
                        basePath={PATH_RUN}
                        idPathVariableName={PATH_VARIABLE_RUN_ID}
                    />
                ))
                .sortable(false)
                .filterable(false)
                .width('70px')
                .cls('text-center')
        )
        .captionControl((ctx) => {
            const onClick = (e: React.MouseEvent) => {
                e.preventDefault();
                const column = new ColumnBuilder<any, any>().field(ROW_SELECT_MOCK_FIELD).build();
                ctx.dispatch({ type: ActionType.HIDE, column });
            };
            const showBtn = ctx.state.request.hidden.includes(ROW_SELECT_MOCK_FIELD);
            return showBtn ? (
                <button className='btn btn-sm btn-primary ml-1' onClick={onClick}>
                    бриф
                </button>
            ) : (
                <></>
            );
        })
        .captionControl((ctx) => {
            const onClickDownload = (e: React.MouseEvent) => {
                e.preventDefault();
                const runIds = ctx.state.selectedRows;
                if (runIds.length) {
                    docs.runsBriefPdf(runIds).then((blob) =>
                        fileUtil.downloadBlob(blob, `Бриф_по_рейсам_${runIds.join('_')}.${FileType.PDF}`));
                }
                hide();
            };
            const hide = () => {
                const column = new ColumnBuilder<any, any>().field(ROW_SELECT_MOCK_FIELD).build();
                ctx.dispatch({ type: ActionType.HIDE, column });
                ctx.dispatch({ type: ActionType.DESELECT_ALL_ROWS });
            };
            const showBtns = !ctx.state.request.hidden.includes(ROW_SELECT_MOCK_FIELD);
            return showBtns ? (
                <>
                    <button className='btn btn-sm btn-primary ml-1 mr-1' onClick={onClickDownload}>
                        сформировать
                    </button>
                    <button className='btn btn-sm btn-secondary mr-1' onClick={hide}>
                        отмена
                    </button>
                </>
            ) : (
                <></>
            );
        })
        .formUrl(generatePath(PATH_RUN, { [PATH_VARIABLE_RUN_ID]: '' }))
        .searchParams((m) =>
            createSearchParams({
                domestic: m.isDomesticRun ? 'true' : 'false',
            })
        )
        .load((ctx) => runs.list(ctx.state.request, domesticParam === 'true'))
        .id('runs')
        .editable(true)
        .tabs([
            {
                name: 'Внешние рейсы',
                path: `${PATH_RUNS}?domestic=false`,
                end: true,
                isActiveFn: (isActive) => isActive && domesticParam === 'false',
            },
            {
                name: 'Внутренние рейсы',
                path: `${PATH_RUNS}?domestic=true`,
                end: true,
                isActiveFn: (isActive) => isActive && domesticParam === 'true',
            },
        ])
        .rowClassNameFn((m) => m.statusId === RunStatus.DATE_CHANGED ? 'has-changes' : '')
        .build();

    return (
        <div className='runs d-flex'>
            <DwTable header='Рейсы' config={config} />
        </div>
    );
};

export default Runs;
