import React, { useContext, useEffect, useMemo, useState } from 'react';
import Dropzone, { ErrorCode, FileError, FileRejection } from 'react-dropzone';
import { AttachmentModel, AttachmentType } from '../../../model/attachment';
import attachmentFiles from '../../../api/attachments';
import users from '../../../api/users';
import { Option } from '../../control/option';
import { RemoveButton } from '../../table/RemoveButton';
import { FormContext, useSetFieldValue, validateField } from '../DwForm';
import { useIsMounted } from '../../../common/isMounted';
import { MimeType } from '../../../model/mimeType';

interface FileType {
    [key: string]: string[];
}

type Props = {
    id: string;
    attachmentType: AttachmentType;
    attachmentSubtype?: Option;
    parentId: number;
    filesLimit?: number;
    acceptedFileTypes?: FileType;
};

type PendingAttachment = {
    attachment: AttachmentModel;
    file: File;
    error?: string;
};

const MAX_FILE_SIZE_MB = 19;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_FILES_PER_ENTITY = 50;

const FILE_TYPES = {
    'image/*': [],
    [MimeType.DOC]: ['.doc'],
    [MimeType.DOCX]: ['.docx'],
    [MimeType.XLS]: ['.xls'],
    [MimeType.XLSX]: ['.xlsx'],
    [MimeType.PDF]: ['.pdf'],
};

const getFileExtensions = (fileTypes: FileType) => Object.values(fileTypes).reduce((acc, val) => acc.concat(val), []);

const UploadArea: React.FC<Props> = ({
    id,
    attachmentType,
    attachmentSubtype,
    parentId,
    filesLimit,
    acceptedFileTypes,
 }) => {
    const context = useContext(FormContext);
    const model = context.state.model;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const attachmentsValue = fieldConfig.getter(model);
    const attachments: AttachmentModel[] = useMemo(() => attachmentsValue || [], [attachmentsValue]);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [attachments]);

    const onChange = (value: AttachmentModel[]) => {
        setValue(id, value);
    };

    const [currentUser, setCurrentUser] = useState<Option | null>(null);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [pendingAttachments, setPendingAttachments] = useState<PendingAttachment[]>([]);

    useEffect(() => {
        users.currentOption().then((user) => setCurrentUser(user));
        return () => {
            abortController?.abort();
        };
    }, []);

    useEffect(() => {
        if (pendingAttachments?.length) {
            uploadNext();
        }
    }, [pendingAttachments]);

    const file2Attachment = (file: File): AttachmentModel => {
        return {
            id: -1,
            created: new Date(),
            fileName: file.name,
            createdBy: currentUser!,
            subtype: attachmentSubtype
        };
    };

    const uploadNext = () => {
        const pendingAttachment = pendingAttachments.find((f) => !f.error);
        if (pendingAttachment) {
            const newAbortController = new AbortController();
            setAbortController(newAbortController);
            const { attachment, attachment: { fileName }, file } = pendingAttachment;
            attachmentFiles
                .upload({ attachmentType, parentId, fileName }, file, newAbortController.signal)
                .then((response: any) => {
                    if (response?.endsWith(fileName)) {
                        onChange([...attachments, attachment]);
                        removePending(pendingAttachment);
                    } else {
                        updatePendingFile(pendingAttachment, { error: 'Ошибка загрузки' });
                    }
                    setAbortController(null);
                });
        }
    };

    const renderProgress = (pendingAttachment: PendingAttachment, index: number) => {
        if (!pendingAttachment.error) {
            return (
                <div className='row' key={`progress-${index}`}>
                    <div key={`progress-${index}`} className='col-lg-1 upload-progress'>
                        <div className='spinner-grow text-primary mr-1' role='status' />
                    </div>
                    <div key={`filename-${index}`} className='col-lg-11 text-break'>
                        {pendingAttachment.attachment.fileName}
                    </div>
                </div>
            );
        }
        return (
            <div className='row' key={`progress-${index}`}>
                <div key={`filename-${index}`} className='col-lg-11 text-break'>
                    {pendingAttachment.attachment.fileName}&nbsp;
                    <strong>{pendingAttachment.error}</strong>
                </div>
                <div className='col-lg-1 text-right actions' key={`actions-${index}`}>
                    <RemoveButton onClick={() => removePending(pendingAttachment)} />
                </div>
            </div>
        );
    };

    const updatePendingFile = (
        pendingAttachment: PendingAttachment,
        newData: Partial<PendingAttachment>
    ) => {
        setPendingAttachments(
            pendingAttachments.map((f) =>
                f.attachment.fileName === pendingAttachment.attachment.fileName
                    ? { ...f, ...newData }
                    : f
            )
        );
    };

    const removePending = (pendingAttachment: PendingAttachment) => {
        setPendingAttachments(
            pendingAttachments.filter(
                (f) => f.attachment.fileName !== pendingAttachment.attachment.fileName
            )
        );
    };

    const getFilesLimit = () => filesLimit ?? MAX_FILES_PER_ENTITY;

    const getFilesLeft = () => {
        const pendingLength = pendingAttachments.filter((f) => f.error == null).length;
        const limitLeft = getFilesLimit() - attachments.length - pendingLength;
        return limitLeft >= 0 ? limitLeft : 0;
    };

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles?.length) {
            const newPendingAttachments = acceptedFiles
                .slice(0, getFilesLeft())
                .map((file: File) => {
                    return {
                        attachment: file2Attachment(file),
                        file: file,
                    };
                });
            setPendingAttachments([...pendingAttachments, ...newPendingAttachments]);
        }
    };

    const getAcceptedFileTypes = () => acceptedFileTypes ?? FILE_TYPES;

    const onDropRejected = (fileRejections: FileRejection[]) => {
        if (fileRejections?.length) {
            const newPendingAttachments = fileRejections
                .slice(0, getFilesLeft())
                .map((fileRejection: FileRejection) => {
                    const file = fileRejection.file;
                    const error = fileRejection.errors
                        .map((e) => {
                            switch (e.code) {
                                case ErrorCode.FileInvalidType:
                                    const extensions = getFileExtensions(getAcceptedFileTypes()).join(', ');
                                    return `Неподдерживаемый тип файла (разрешены ${extensions})`;
                                case ErrorCode.FileTooLarge:
                                    return `Слишком большой размер файла (макс. ${MAX_FILE_SIZE_MB}МБ)`;
                                default:
                                    return e.message;
                            }
                        })
                        .join('\n');
                    return {
                        attachment: file2Attachment(file),
                        file: file,
                        error: error,
                    };
                });
            setPendingAttachments([...pendingAttachments, ...newPendingAttachments]);
        }
    };

    const fileValidator = (file: File) => {
        const isDistinct = attachments.findIndex((af) => af.fileName === file.name) === -1;
        const response: FileError = {
            message: isDistinct ? '' : 'Файл с таким названием уже был загружен',
            code: '',
        };
        return response;
    };

    const filesLeft = getFilesLeft();

    return (
        <>
            <Dropzone
                onDrop={onDrop}
                onDropRejected={onDropRejected}
                validator={fileValidator}
                accept={getAcceptedFileTypes()}
                maxSize={MAX_FILE_SIZE_BYTES}
                disabled={filesLeft === 0}
                useFsAccessApi={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {filesLeft > 0 ? (
                            <>
                                <div className='drop-tip'>
                                    Перетащите файлы или нажмите, чтобы добавить
                                </div>
                                <div>
                                    Доступно для загрузки еще {filesLeft} файлов. Макс. размер файла{' '}
                                    {MAX_FILE_SIZE_MB}MB.
                                </div>
                            </>
                        ) : (
                            <div className='drop-tip-restricted'>
                                Вы загрузили максимальное количество файлов
                            </div>
                        )}
                    </div>
                )}
            </Dropzone>
            {pendingAttachments && (
                <div className='container-fluid mt-3 mb-3'>
                    {pendingAttachments.map((pendingAttachment, index) =>
                        renderProgress(pendingAttachment, index)
                    )}
                </div>
            )}
        </>
    );
};

export default UploadArea;
