import React, {useContext, useMemo} from 'react';
import {generatePath, NavigateFunction, Outlet} from 'react-router-dom';
import {RunFormContext} from '../RunForm';
import {
    PATH_RUN_TALMAN,
    PATH_RUN_TALMANS,
    PATH_VARIABLE_RUN_ID,
    PATH_VARIABLE_SHIPMENT_DETAIL_ID
} from '../../../routerPaths';
import util from '../../../common/util';
import {Config} from '../../table/config';
import {TalmanListModel} from '../model/talman/talmanModel';
import {DwTable} from '../../table/DwTable';
import {TalmanField} from './talmanField';
import talmans from '../../../api/talman';
import docs from '../../../api/docs';
import {Context} from "../../table/context";
import {ActionType} from "../../table/action";
import fileUtil from '../../../common/fileUtil';
import { FileType } from '../../../model/fileType';

const useTableConfig: (
    runId: number,
) => Config<TalmanListModel> = (runId) =>
    useMemo(() => {
        return Config.builder<TalmanListModel>()
            .column((builder) =>
                builder
                    .text('Номер заказа', TalmanField.QUOTE_NUMBER)
                    .getter((model) => model.quoteNumber ?? model.quoteId)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Номенклатура', TalmanField.ASSORTMENT)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Тара', TalmanField.TARE_TMC)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .integer('Кол-во тары', TalmanField.TARE_QUANTITY)
                    .sortable(false)
                    .filterable(false)
                    .cls('number')
            )
            .column((builder) =>
                builder
                    .integer('Кол-во распределенной тары', TalmanField.TARE_DISTRIBUTED)
                    .sortable(false)
                    .filterable(false)
                    .cls('number')
            )
            .column((builder) =>
                builder
                    .text('Пломба', TalmanField.SEAL)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Склад разгрузки', TalmanField.UNLOADING_STORAGE)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Место инспекции', TalmanField.INSPECTION_PLACE)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .dateTime('Дата и время инспекции', TalmanField.INSPECTION_DATETIME)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Транспорт', TalmanField.VEHICLES_INFO)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .dateTime('Дата и время погрузки', TalmanField.LOADING_DATETIME)
                    .sortable(false)
                    .filterable(false)
            )
            .columnsSelector(false)
            .withAdd(false)
            .captionControl((ctx) => {
                const onClick = (e: React.MouseEvent) => {
                    e.preventDefault();
                    docs.talmanPalletPassportsPdf(runId).then((blob) =>
                        fileUtil.downloadBlob(blob, `Список_Тальманских_расписок_по_рейсу_${runId}.${FileType.PDF}`));
                };
                return (
                    <button className='btn btn-sm btn-primary ml-1' onClick={onClick}>
                        скачать в pdf
                    </button>
                );
            })
            .formUrl(
                generatePath(PATH_RUN_TALMANS, {
                    [PATH_VARIABLE_RUN_ID]: util.toString(runId),
                })
            )
            .load((ctx) => talmans.list(ctx.state.request, runId))
            .onClick((shipmentDetailId: number, navigate: NavigateFunction, _: TalmanListModel, ctx: Context) => {
                    ctx.dispatch({type: ActionType.DESELECT_ALL_ROWS});
                    ctx.dispatch({type: ActionType.SELECT_ROW, id: shipmentDetailId});
                    navigate(
                        generatePath(PATH_RUN_TALMAN, {
                            [PATH_VARIABLE_RUN_ID]: util.toString(runId),
                            [PATH_VARIABLE_SHIPMENT_DETAIL_ID]: util.toString(shipmentDetailId),
                        })
                    )
                }
            )
            .id('talmans')
            .build();
    }, [runId]);

export const TalmanTable: React.FC = () => {
    const {run: {id: runId}} = useContext(RunFormContext);
    const config = useTableConfig(runId);
    return (
        <>
            <div className='talmans d-flex mb-3'>
                <DwTable header='Тальманская расписка' config={config}/>
            </div>
            <Outlet/>
        </>
    );
};
