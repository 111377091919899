import {Config} from '../table/config';
import {RunListModel} from './model/runListModel';
import {RunField} from './runField';
import {ColumnType} from '../table/columnType';

export const ROW_SELECT_MOCK_FIELD = 'select';

export const baseConfigBuilder = () => {
    return Config.builder<RunListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.client('Заказчик', RunField.CLIENT))
        .column((builder) =>
            builder
                .label('Статус ТТН')
                .field(RunField.TTN_STATUS)
                .type(ColumnType.TTN_STATUS)
                .cls('status')
        )
        .column((builder) => builder.text('Номер ТТН', RunField.TTN_NUMBER))
        .column((builder) => builder.date('Дата ТТН', RunField.TTN_DATE))
        .column((builder) => builder.text('Водитель', RunField.DRIVER))
        .column((builder) =>
            builder.text('Телефон водителя', RunField.DRIVER_PHONE).sortable(false)
        )
        .column((builder) => builder.text('Тягач', RunField.TRUCK))
        .column((builder) => builder.text('Полуприцеп', RunField.TRAILER))
        .column((builder) => builder.description('Описание', RunField.DESCRIPTION).sortable(false))
        .column((builder) => builder.date('Создан', RunField.CREATED))
        .column((builder) =>
            builder
                .label('Менеджер КО')
                .field(RunField.COMMERCIAL_MANAGER)
                .type(ColumnType.COMMERCIAL_MANAGER)
        )
        .column((builder) =>
            builder
                .label('Менеджер ОЛ')
                .field(RunField.LOGISTIC_MANAGER)
                .type(ColumnType.LOGISTIC_MANAGER)
        )

        .column((builder) => builder.date('Дата погрузки', RunField.LOADING_DATE))
        .column((builder) => builder.text('Склад погрузки', RunField.LOADING_STORAGE).sortable(false))
        .column((builder) => builder.text('Адрес погрузки', RunField.LOADING_ADDRESS).sortable(false))

        .column((builder) => builder.date('Дата разгрузки', RunField.UNLOADING_DATE))
        .column((builder) => builder.text('Склад разгрузки', RunField.UNLOADING_STORAGE).sortable(false))
        .column((builder) => builder.text('Адрес разгрузки', RunField.UNLOADING_ADDRESS).sortable(false))

        .column((builder) => builder.text('Номер заказа', RunField.QUOTE_NUMBER).sortable(false))
        .column((builder) =>
            builder.label('Тип доставки').field(RunField.TYPE).cls('type').type(ColumnType.RUN_TYPE)
        )

        .column((builder) =>
            builder.boolean('Навал', RunField.IS_BULK).sortable(false).cls('boolean')
        )
        .column((builder) => builder.company('Организация для заказчика', RunField.CLIENT_COMPANY))
        .column((builder) => builder.text('Договор с заказчиком', RunField.CLIENT_CONTRACT_NO))
        .column((builder) => builder.integer('Ставка заказчика', RunField.CLIENT_PRICE).cls('money'))
        .column((builder) =>
            builder
                .label('Форма оплаты заказчика')
                .field(RunField.CLIENT_PAYMENT_METHOD)
                .cls('nds')
                .type(ColumnType.PAYMENT_METHOD)
        )
        .column((builder) =>
            builder
                .label('Условия оплаты заказчика')
                .field(RunField.CLIENT_PAYMENT_TYPE)
                .type(ColumnType.PAYMENT_TYPE)
        )
        .column((builder) =>
            builder
                .integer('Отсрочка платежа заказчика', RunField.CLIENT_PAYMENT_DELAY)
                .cls('text-center')
                .getter((model) =>
                    model.clientPaymentDelay > 0 ? model.clientPaymentDelay + ' дн.' : '-'
                )
        )
        .column((builder) =>
            builder.date('Плановая дата оплаты заказчика', RunField.CLIENT_PLANNED_PAYMENT_DATE)
        )
        .column((builder) =>
            builder.date('Фактическая дата оплаты заказчика', RunField.CLIENT_PAYMENT_DATE)
        )
        .column((builder) => builder.text('Контактное лицо заказчика', RunField.CLIENT_CONTACT))
        .column((builder) =>
            builder.company('Организация для перевозчика', RunField.CARRIER_COMPANY)
        )
        .column((builder) => builder.carrier('Перевозчик', RunField.CARRIER))
        .column((builder) => builder.text('Договор с перевозчиком', RunField.CARRIER_CONTRACT_NO))
        .column((builder) => builder.integer('Ставка перевозчика', RunField.CARRIER_PRICE).cls('money'))
        .column((builder) =>
            builder
                .label('Способ оплаты перевозчика')
                .field(RunField.CARRIER_PAYMENT_METHOD)
                .cls('nds')
                .type(ColumnType.PAYMENT_METHOD)
        )
        .column((builder) =>
            builder
                .label('Форма оплаты перевозчика')
                .field(RunField.CARRIER_PAYMENT_TYPE)
                .type(ColumnType.PAYMENT_TYPE)
        )
        .column((builder) =>
            builder
                .integer('Отсрочка платежа перевозчика', RunField.CARRIER_PAYMENT_DELAY)
                .cls('text-center')
                .getter((model) =>
                    model.carrierPaymentDelay > 0 ? model.carrierPaymentDelay + ' дн.' : '-'
                )
        )
        .column((builder) =>
            builder
                .date('Плановая дата оплаты перевозчика', RunField.CARRIER_PLANNED_PAYMENT_DATE)
                .sortable(false)
        )
        .column((builder) =>
            builder.date('Фактическая дата оплаты перевозчика', RunField.CARRIER_PAYMENT_DATE)
        )
        .column((builder) => builder.text('Контактное лицо перевозчика', RunField.CARRIER_CONTACT))
        .column((builder) => builder.money('Стоимость груза', RunField.CARGO_PRICE).cls('money'))
        .column((builder) => builder.integer('Общий вес', RunField.CARGO_WEIGHT))

        .column((builder) =>
            builder
                .label('Статус склада')
                .field(RunField.STORAGE_STATUS)
                .type(ColumnType.STORAGE_STATUS)
                .sortable(false)
        )

        .defaultHidden([
            RunField.COMMERCIAL_MANAGER,
            RunField.LOGISTIC_MANAGER,

            RunField.CLIENT_COMPANY,
            RunField.CLIENT_CONTRACT_NO,
            RunField.CLIENT_PAYMENT_METHOD,
            RunField.CLIENT_PAYMENT_TYPE,
            RunField.CLIENT_PAYMENT_DELAY,
            RunField.CLIENT_PLANNED_PAYMENT_DATE,
            RunField.CLIENT_PAYMENT_DATE,
            RunField.CLIENT_CONTACT,

            RunField.CARRIER_COMPANY,
            RunField.CARRIER,
            RunField.CARRIER_CONTRACT_NO,
            RunField.CARRIER_PAYMENT_METHOD,
            RunField.CARRIER_PAYMENT_TYPE,
            RunField.CARRIER_PAYMENT_DELAY,
            RunField.CARRIER_PLANNED_PAYMENT_DATE,
            RunField.CARRIER_PAYMENT_DATE,
            RunField.CARRIER_EXPENSES,

            RunField.CARGO_PRICE,
            RunField.DESCRIPTION,
            ROW_SELECT_MOCK_FIELD,
        ]);
};
