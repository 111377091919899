import React from 'react';
import { generatePath } from 'react-router-dom';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { PalletPassportTemplateField } from './palletPassportTemplateField';
import { PalletPassportTemplateListModel } from '../../../model/palletPassportTemplate/palletPassportTemplateModel';
import palletPassportTemplates from '../../../api/palletPassportTemplate';
import { PATH_CP_PALLET_PASSPORT_TEMPLATE, PATH_VARIABLE_PALLET_PASSPORT_TEMPLATE_ID } from '../../../routerPaths';

const config = Config.builder<PalletPassportTemplateListModel>()
        .column((builder) => builder.id().filterable(false))
        .column((builder) =>
            builder.text('Наименование', PalletPassportTemplateField.NAME).sortable(false)
        )
        .column((builder) =>
            builder
                .cls('text-center')
                .sortable(false)
                .filterable(false)
                .getter((model, _, refresh) => (
                    model.id > 1 ? (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () => palletPassportTemplates.delete(model.id))
                            }
                        />
                    ) : null
                ))
                .width('45px')
        )
        .formUrl(generatePath(PATH_CP_PALLET_PASSPORT_TEMPLATE, { [PATH_VARIABLE_PALLET_PASSPORT_TEMPLATE_ID]: '' }))
        .load((ctx) => palletPassportTemplates.list(ctx.state.request))
        .id('pallet-passport-templates')
        .defaultHidden([])
        .build();

const PalletPassportTemplateList: React.FC = () => {
    return (
        <div className='pallet-passport-templates d-flex'>
            <DwTable header='Печатные формы паспортов паллет' config={config} />
        </div>
    );
};

export default PalletPassportTemplateList;
