import type {MouseEvent} from 'react';
import React, {Fragment, useCallback, useContext, useMemo} from 'react';
import type {ClientModel} from '../../../model/client/clientModel';
import {ClientType} from '../../../model/client/clientModel';
import {Role} from '../../../model/role';
import {DictionaryType} from '../../control/option';
import {showSuccessToast, showWarningToast} from '../../control/showToast';
import CheckboxInput from '../../form/CheckboxInput';
import ClientBasisOptions from '../../form/ClientBasisOptions';
import Companies from '../../form/Companies';
import {DwForm, FormContext, useFieldValue, useSetFieldValue} from '../../form/DwForm';
import StringInput from '../../form/StringInput';
import TextInput from '../../form/TextInput';
import {ClientField} from './clientField';
import Options from '../../form/Options';
import Users from '../../form/Users';
import AttachmentsArea from '../../form/attachments/AttachmentsArea';
import {AttachmentModel, AttachmentType} from '../../../model/attachment';
import {FormConfig} from '../../form/formConfig';
import {
    atiCodeValidator,
    atiRatingValidator,
    clientInnValidator,
    clientKppValidator,
    clientOgrnValidator,
    clientPhoneValidator,
    emailValidator,
    fullNameValidator,
    headValidator,
} from '../../../common/validation/clientValidators';
import clients from '../../../api/clients';
import ContextError from '../../form/ContextError';
import {ClientFormContext} from './ClientForm';
import {PATH_CP_CLIENTS, PATH_VARIABLE_CLIENT_ID} from '../../../routerPaths';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import CountryOptions from "../../form/CountryOptions";

const useFormConfig = (client: ClientModel) =>
    useMemo(
        () =>
            FormConfig.builder<ClientModel>()
                .number(
                    ClientField.ID,
                    (m) => m.id,
                    (m, v) => (m.id = v)
                )
                .requiredOption(
                    ClientField.TYPE,
                    (m) => m.type,
                    (m, v) => (m.type = v)
                )
                .requiredOption(
                    ClientField.KIND,
                    (m) => m.kind,
                    (m, v) => (m.kind = v)
                )
                .option(
                    ClientField.COMPANY,
                    (m) => m.company,
                    (m, v) => (m.company = v)
                )
                .option(
                    ClientField.LOGISTIC_MANAGER,
                    (m) => m.logisticManager,
                    (m, v) => (m.logisticManager = v)
                )
                .option(
                    ClientField.COMMERCIAL_MANAGER,
                    (m) => m.commercialManager,
                    (m, v) => (m.commercialManager = v)
                )
                .boolean(
                    ClientField.ACTIVE,
                    (m) => m.active,
                    (m, v) => (m.active = v)
                )
                .boolean(
                    ClientField.ACCREDITED,
                    (m) => m.accredited,
                    (m, v) => (m.accredited = v)
                )
                .boolean(
                    ClientField.ALIEN,
                    (m) => m.alien,
                    (m, v) => (m.alien = v)
                )
                .option(
                    ClientField.ISO_CODE,
                    (m) => m.isoCode,
                    (m, v) => (m.isoCode = v)
                )
                .requiredText(
                    ClientField.NAME,
                    (m) => m.name,
                    (m, v) => (m.name = v)
                )
                .boolean(
                    ClientField.CURRENCY_CONTROL,
                    (m) => m.currencyControl,
                    (m, v) => (m.currencyControl = v)
                )
                .text(
                    ClientField.FULL_NAME,
                    (m) => m.fullName,
                    (m, v) => (m.fullName = v),
                    (m) => fullNameValidator(m)
                )
                .text(
                    ClientField.INN,
                    (m) => m.inn,
                    (m, v) => (m.inn = v),
                    (m) => clientInnValidator(m)
                )
                .boolean(
                    ClientField.ALIEN_INN,
                    (m) => m.alienInn,
                    (m, v) => (m.alienInn = v)
                )
                .text(
                    ClientField.KPP,
                    (m) => m.kpp,
                    (m, v) => (m.kpp = v),
                    (m) => clientKppValidator(m)
                )
                .text(
                    ClientField.OGRN,
                    (m) => m.ogrn,
                    (m, v) => (m.ogrn = v),
                    (m) => clientOgrnValidator(m)
                )
                .text(
                    ClientField.PHONE,
                    (m) => m.phone,
                    (m, v) => (m.phone = v),
                    (m) => clientPhoneValidator(m)
                )
                .text(
                    ClientField.EMAIL,
                    (m) => m.email,
                    (m, v) => (m.email = v),
                    (m) => emailValidator(m)
                )
                .text(
                    ClientField.WEB,
                    (m) => m.web,
                    (m, v) => (m.web = v)
                )
                .text(
                    ClientField.POSTAL_ADDRESS,
                    (m) => m.postalAddress,
                    (m, v) => (m.postalAddress = v)
                )
                .text(
                    ClientField.ACTUAL_ADDRESS,
                    (m) => m.actualAddress,
                    (m, v) => (m.actualAddress = v)
                )
                .text(
                    ClientField.ADDRESS,
                    (m) => m.address,
                    (m, v) => (m.address = v)
                )
                .text(
                    ClientField.HEAD,
                    (m) => m.head,
                    (m, v) => (m.head = v),
                    (m) => headValidator(m)
                )
                .text(
                    ClientField.HEAD_POSITION,
                    (m) => m.headPosition,
                    (m, v) => (m.headPosition = v)
                )
                .text(
                    ClientField.HEAD_GENITIVE,
                    (m) => m.headGenitive,
                    (m, v) => (m.headGenitive = v)
                )
                .option(
                    ClientField.BASIS,
                    (m) => m.basis,
                    (m, v) => (m.basis = v)
                )
                .text(
                    ClientField.ATI_RATING,
                    (m) => m.atiRating,
                    (m, v) => (m.atiRating = v),
                    (m) => atiRatingValidator(m)
                )
                .text(
                    ClientField.ATI_CODE,
                    (m) => m.atiCode,
                    (m, v) => (m.atiCode = v),
                    (m) => atiCodeValidator(m)
                )
                .option(
                    ClientField.STATUS,
                    (m) => m.status,
                    (m, v) => (m.status = v)
                )
                .text(
                    ClientField.COMMENT,
                    (m) => m.comment,
                    (m, v) => (m.comment = v)
                )
                .list<AttachmentModel>(
                    ClientField.ATTACHMENTS,
                    (m) => m.attachments,
                    (m, v) => (m.attachments = v)
                )
                .load((_) => Promise.resolve(client))
                .initialModelGetter((_) => Promise.resolve(client))
                .submit(clients.save)
                .idPathVariableName(PATH_VARIABLE_CLIENT_ID)
                .redirectUrl(PATH_CP_CLIENTS)
                .build(),
        [client]
    );

const Client: React.FC = () => {
    const {client} = useContext(ClientFormContext);
    const formConfig = useFormConfig(client);
    return (
        <DwForm config={formConfig}>
            <ClientInner/>
            <ContextError/>
        </DwForm>
    );
};

const ClientInner: React.FC = () => {
    const context = useContext(FormContext);
    const id = useFieldValue(ClientField.ID);
    const clientType = useFieldValue(ClientField.TYPE);
    const isAlien = useFieldValue(ClientField.ALIEN);
    const {model} = context.state;
    const setValue = useSetFieldValue();

    const updateFormValues = useCallback(
        (client: ClientModel) => {
            if (Object.keys(client)?.length > 0) {
                setValue(ClientField.FULL_NAME, client.fullName);
                setValue(ClientField.NAME, client.name);
                setValue(ClientField.INN, client.inn);
                setValue(ClientField.OGRN, client.ogrn);
                setValue(ClientField.KPP, client.kpp);
                setValue(ClientField.ADDRESS, client.address);
                setValue(ClientField.HEAD, client.head);
                setValue(ClientField.HEAD_POSITION, client.headPosition);
                setValue(ClientField.HEAD_GENITIVE, client.headGenitive);
                setValue(ClientField.BASIS, client.basis);
                setValue(ClientField.ACTIVE, client.active);
                setValue(ClientField.ACCREDITED, client.accredited);
                setValue(ClientField.PHONE, client.phone);
                setValue(ClientField.EMAIL, client.email);
            }
        },
        [setValue]
    );

    const onSuggestion = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const suggest = async () => {
                const client: ClientModel = await clients.getSuggestion(model as ClientModel);

                if (Object.keys(client)?.length !== 0) {
                    updateFormValues(client);
                    showSuccessToast('Контрагент найден');
                } else {
                    showWarningToast('Контрагент не найден');
                }
            };

            suggest().then();
        },
        [model, updateFormValues]
    );

    const onClean = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const emptyModel = {
                fullName: '',
                name: '',
                inn: '',
                ogrn: '',
                kpp: '',
                address: '',
                head: '',
                headPosition: '',
                headGenitive: '',
                basis: {},
                active: false,
                accredited: false,
                phone: '',
                email: '',
            } as ClientModel;
            updateFormValues(emptyModel);
        },
        [updateFormValues]
    );

    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            Полное наименование Контрагента
                            <StringInput id={ClientField.FULL_NAME}/>
                        </div>
                        <div className='col-lg-4'>
                            Краткое наименование Контрагента
                            <StringInput id={ClientField.NAME}/>
                        </div>
                        <div className='col-lg-4'>
                            <div className={'row'}>
                                <div className='col'>
                                    Тип Контрагента
                                    <Options
                                        id={ClientField.TYPE}
                                        dictionaryType={DictionaryType.CLIENT_TYPE}
                                    />
                                </div>
                                <div className='col'>
                                    Вид Контрагента
                                    <Options
                                        id={ClientField.KIND}
                                        dictionaryType={DictionaryType.CLIENT_KIND}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-3'>
                            Должность руководителя
                            <StringInput id={ClientField.HEAD_POSITION}/>
                        </div>
                        <div className='col-lg-3'>
                            ФИО руководителя
                            <StringInput id={ClientField.HEAD}/>
                        </div>
                        <div className='col-lg-3'>
                            В Лице
                            <StringInput id={ClientField.HEAD_GENITIVE}/>
                        </div>
                        <div className='col-lg-3'>
                            Действует на основании
                            <ClientBasisOptions id={ClientField.BASIS}/>
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col-lg-4'>
                            ИНН
                            <StringInput id={ClientField.INN}/>
                        </div>
                        <div className='col-lg-4'>
                            ОГРН
                            <StringInput id={ClientField.OGRN}/>
                        </div>
                        <div className='col-lg-4'>
                            КПП
                            <StringInput id={ClientField.KPP}/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            Почтовый адрес
                            <StringInput id={ClientField.POSTAL_ADDRESS}/>
                        </div>
                        <div className='col-lg-4'>
                            Фактический адрес
                            <StringInput id={ClientField.ACTUAL_ADDRESS}/>
                        </div>
                        <div className='col-lg-4'>
                            Юридический адрес
                            <StringInput id={ClientField.ADDRESS}/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            Телефон
                            <StringInput id={ClientField.PHONE}/>
                        </div>
                        <div className='col-lg-4'>
                            E-mail
                            <StringInput id={ClientField.EMAIL}/>
                        </div>
                        <div className='col-lg-4'>
                            Сайт
                            <StringInput id={ClientField.WEB}/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            {[ClientType.CLIENT, ClientType.CLIENT_OR_CARRIER].includes(
                                clientType?.value
                            ) && (
                                <>
                                    Менеджер КО
                                    <Users
                                        id={ClientField.COMMERCIAL_MANAGER}
                                        role={Role.COMMERCIAL}
                                    />
                                </>
                            )}
                            {ClientType.CARRIER === clientType?.value && (
                                <>
                                    Менеджер ОЛ
                                    <Users
                                        id={ClientField.LOGISTIC_MANAGER}
                                        role={Role.LOGISTICIAN}
                                    />
                                </>
                            )}
                        </div>
                        <div className='col-lg-4'>
                            Рейтинг
                            <StringInput id={ClientField.ATI_RATING}/>
                        </div>
                        <div className='col-lg-4'>
                            Код АТИ
                            <StringInput id={ClientField.ATI_CODE}/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='row mb-2'>
                                <div className='col-lg-4 col-sm-12'>
                                    <CheckboxInput id={ClientField.ACTIVE} label={'Активен'}/>
                                </div>
                                <div className='col-lg-4 col-sm-12'>
                                    <CheckboxInput
                                        id={ClientField.ALIEN}
                                        label={'Контрагент не РФ'}
                                    />
                                </div>
                                <div className='col-lg-4 col-sm-12'>
                                    {isAlien && <Fragment>
                                        Страна
                                        <CountryOptions id={ClientField.ISO_CODE}/>
                                    </Fragment>}
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-12 col-sm-12'>
                                    <CheckboxInput
                                        id={ClientField.CURRENCY_CONTROL}
                                        label={'Подлежит валютному контролю'}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-12'>
                                    <CheckboxInput
                                        id={ClientField.ACCREDITED}
                                        label={'Аккредитован'}
                                    />
                                </div>
                                <div className='col-lg-6 col-sm-12'>
                                    Организация
                                    <Companies id={ClientField.COMPANY}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            Статус
                            <Options
                                id={ClientField.STATUS}
                                dictionaryType={DictionaryType.CLIENT_STATUS}
                            />
                        </div>
                        <div className='col-lg-6'>
                            Заметки
                            <TextInput id={ClientField.COMMENT} rows={6}/>
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <AttachmentsArea
                                id={ClientField.ATTACHMENTS}
                                attachmentType={AttachmentType.CLIENT}
                                parentId={id}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <button className='btn btn-sm btn-primary' onClick={onSuggestion}>
                    Заполнить
                </button>
                &nbsp;
                <SaveAndCancelButtonGroup/>
                &nbsp;
                <button className='btn btn-sm btn-outline-primary' onClick={onClean}>
                    Очистить
                </button>
            </div>
        </div>
    );
};

export default Client;
