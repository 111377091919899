import React, {MouseEvent, useContext, useEffect} from 'react';
import {FormContext, useSetFieldValue, validateField} from './DwForm';
import AjaxSelect from '../control/AjaxSelect';
import FormInvalidText from './FormInvalidText';

import type {MultiValue, SingleValue} from 'react-select/dist/declarations/src/types';
import type {Option} from '../control/option';
import {useIsMounted} from "../../common/isMounted";
import {FormatOptionLabelMeta} from 'react-select';

type Props = {
    id: string;
    loadOptions: (inputValue: string, callback: (options: Option[]) => void) => void;
    cacheOptions?: boolean;
    onChange?: (option: SingleValue<Option> | MultiValue<Option>) => void;
    readOnly?: boolean;
    isMulti?: boolean;
    nullable?: boolean;
    formatOptionLabel?: ((data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => React.ReactNode);
    defaultValue?: Option;
};

const FormSelect: React.FC<Props> = ({
    id,
    onChange,
    nullable,
    readOnly,
    defaultValue,
    ...rest
}) => {
    const context = useContext(FormContext);
    const {config, model, errors} = context.state;
    const setValue = useSetFieldValue();
    const fieldConfig = config.field(id);
    const error = errors.get(id);
    const value = fieldConfig.getter(model) as Option;
    const clearable = !readOnly && nullable;

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted && value?.value) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value?.value]);

    const onChangeHandler = async (option: SingleValue<Option> | MultiValue<Option>) => {
        if (onChange) {
            onChange(option);
        }
        setValue(id, option);
    };

    const onResetEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        clearable && onChangeHandler({ value: -1, label: '' });
    };
    return (
        <div className='form-group'>
            <div className={nullable ? 'input-group' : ''}>
            <AjaxSelect {...{
                ...rest,
                onChange: onChangeHandler,
                value: defaultValue ? (value?.value ? value : defaultValue) : value,
                error,
                readOnly
            }} />
            {clearable && value?.value > 0 &&
                <div className="input-group-append">
                    <button className='btn btn-sm btn-outline-secondary' onClick={onResetEventHandler} tabIndex={-1}>
                        <i className="fas fa-times" />
                    </button>
                </div>}
        </div>
            <FormInvalidText error={error} />
        </div>
    );
};

export default FormSelect;
