import React from 'react';
import {BaseOptionRequest} from '../../api/baseOptionRequest';
import FormSelect from './FormSelect';
import addresses from '../../api/addresses';
import type {MultiValue, SingleValue} from 'react-select/dist/declarations/src/types';
import type {Option} from '../control/option';

type Props = {
    id: string;
    onChange?: (newValue: SingleValue<Option>) => void;
};

const CountryOptions: React.FC<Props> = ({id, onChange}) => {
    const loadOptions = async (inputValue: string, callback: (options: Option[]) => void) => {
        const filter: BaseOptionRequest = {filter: inputValue};
        const res: Option[] = await addresses.getCountries(filter);
        return callback(res);
    };
    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
        />
    );
};

export default CountryOptions;
