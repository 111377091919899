import {deleteRequest, get, post} from './rest';

import type {Option} from '../component/control/option';
import type {ApiResponse} from './apiResponse';
import type {ClientContact, ClientManagerRequest, ClientModel, ClientOptionRequest,} from '../model/client/clientModel';
import type {ClientListModel} from '../model/client/clientListModel';
import type {PagedResponse} from './pagedResponse';
import type {ListRequest} from '../component/table/listRequest';
import type {ClientActiveDriversRequest} from '../component/runs/model/clientActiveDriversRequest';
import type {ClientConsigneeListModel} from '../model/client/clientConsigneeListModel';
import type {ClientConsigneeRequest} from '../model/client/ClientConsigneeRequest';
import type {BaseOptionRequest} from './baseOptionRequest';
import {ClientSettingsModel} from '../model/client/clientSettingsModel';

const PATH_BASE = 'clients';

const clients = {
    options: (request: ClientOptionRequest) => post<Option[]>(`${PATH_BASE}/options`, request),
    get: (id: number) => get<ClientModel>(`${PATH_BASE}/${id}`),
    contact: (id: number) => get<ClientContact>(`${PATH_BASE}/${id}/contact`),
    save: (model: ClientModel) => post<ApiResponse>(PATH_BASE, model),
    list: (model: ListRequest) => post<PagedResponse<ClientListModel>>(`${PATH_BASE}/list`, model),
    isInnKppUnique: (id: number, inn: string, kpp: string) =>
        post<ApiResponse>(`${PATH_BASE}/inn-kpp/unique`, {id, inn, kpp}),
    getSuggestion: (model: ClientModel) => post<ClientModel>(`${PATH_BASE}/suggestion`, model),
    findActiveDrivers: (request: ClientActiveDriversRequest) =>
        post<Option[]>(`${PATH_BASE}/options/active-drivers`, request),
    findClientManager: (request: ClientManagerRequest) =>
        post<Option>(`${PATH_BASE}/options/client/manager`, request),

    clientConsigneeList: (model: ListRequest, clientId: number) =>
        post<PagedResponse<ClientConsigneeListModel>>(`${PATH_BASE}/${clientId}/consignees`, model),
    getClientConsignee: (clientId: number, consigneeId: number) =>
        get<{ consignee: Option }>(`${PATH_BASE}/client/${clientId}/consignees/${consigneeId}`),
    saveClientConsignee: (
        model: { consignee: Option; clientId: number },
        prevConsigneeId: number
    ) => post<ApiResponse>(`${PATH_BASE}/client/consignees/${prevConsigneeId}`, model),
    deleteClientConsignee: (clientId: number, consigneeId: number) =>
        deleteRequest<ApiResponse>(`${PATH_BASE}/client/${clientId}/consignees/${consigneeId}`),
    clientConsigneeOptions: (request: ClientConsigneeRequest) =>
        post<Option[]>(`${PATH_BASE}/options/consignee`, request),
    getAvailableConsigneesForClient: (request: ClientConsigneeRequest) =>
        post<Option[]>(`${PATH_BASE}/options/client/available-consignees`, request),
    clientBasisOptions: (request: BaseOptionRequest) =>
        post<Option[]>(`${PATH_BASE}/options/basis`, request),

    getSettings: (id: number) => get<ClientSettingsModel>(`${PATH_BASE}/${id}/settings`),
    saveSettings: (id: number, model: ClientSettingsModel) => post<ApiResponse>(`${PATH_BASE}/${id}/settings`, model),
};

export default clients;
