import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import StringInput from '../../../form/StringInput';
import Clients from '../../../form/Clients';
import Companies from '../../../form/Companies';
import CheckboxInput from '../../../form/CheckboxInput';
import DtPicker from '../../../form/DtPicker';
import Options from '../../../form/Options';
import { DictionaryType, Option } from '../../../control/option';
import NumberInput from '../../../form/NumberInput';
import { DwForm, FormContext, useFieldValue, useSetFieldValue } from '../../../form/DwForm';
import { FormConfig } from '../../../form/formConfig';
import { ContractModel } from '../../../../model/contract/contractModel';
import {
    notNullablePositiveIntValidator,
    nullableOrPositiveIntValidator
} from '../../../../common/validation/simpleValidators';
import {
    mainContractUniqueValidator,
    accountValidator,
} from '../../../../common/validation/clientValidators';
import { ClientType } from '../../../../model/client/clientModel';
import { ContractField } from './clientContractField';
import TextEditor from '../../../form/TextEditor';
import ClientAccounts from '../../../form/ClientAccounts';
import AttachmentsArea from '../../../form/attachments/AttachmentsArea';
import { AttachmentModel, AttachmentType } from '../../../../model/attachment';
import contracts from '../../../../api/contracts';
import companies from '../../../../api/companies';
import ContextError from '../../../form/ContextError';
import { ClientFormContext} from '../ClientForm';
import { PATH_CP_CLIENT_CONTRACTS, PATH_VARIABLE_CLIENT_CONTRACT_ID } from '../../../../routerPaths';
import util from '../../../../common/util';
import { useRefreshContextAction } from '../../../table/DwTable';
import { SingleValue } from 'react-select';
import {
    CARRIER_CONTRACT_TYPES,
    CLIENT_CONTRACT_TYPES,
    CLIENT_OR_CARRIER_CONTRACT_TYPES,
    SUPPLIER_CONTRACT_TYPES,
    CLIENT_OR_SUPPLIER_TYPES,
    ContractType,
} from '../../../../model/enums/ContractType';
import docs from '../../../../api/docs';
import { FileType } from '../../../../model/fileType';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';
import fileUtil from '../../../../common/fileUtil';

const useFormConfig = (refresh: () => void, clientId: number) =>
    useMemo(
        () =>
            FormConfig.builder<ContractModel>()
                .number(
                    ContractField.ID,
                    (m) => m.id,
                    (m, v) => (m.id = v)
                )
                .requiredOption(
                    ContractField.TYPE,
                    (m) => m.type,
                    (m, v) => (m.type = v)
                )
                .requiredOption(
                    ContractField.STATUS,
                    (m) => m.status,
                    (m, v) => (m.status = v)
                )
                .requiredText(
                    ContractField.NUMBER,
                    (m) => m.number,
                    (m, v) => (m.number = v)
                )
                .requiredDateTime(
                    ContractField.BEGIN_DATE,
                    (m) => m.beginDate,
                    (m, v) => (m.beginDate = v)
                )
                .dateTime(
                    ContractField.END_DATE,
                    (m) => m.endDate,
                    (m, v) => (m.endDate = v)
                )
                .boolean(
                    ContractField.MAIN,
                    (m) => m.main,
                    (m, v) => (m.main = v),
                    (m) => mainContractUniqueValidator(m)
                )
                .boolean(
                    ContractField.HAS_ADDITIONAL,
                    (m) => m.hasAdditional,
                    (m, v) => (m.hasAdditional = v)
                )
                .text(
                    ContractField.DOCUMENTS,
                    (m) => m.documents,
                    (m, v) => (m.documents = v)
                )
                .number(
                    ContractField.PAYMENT_DELAY,
                    (m) => m.paymentDelay,
                    (m, v) => (m.paymentDelay = v),
                    (m) => notNullablePositiveIntValidator(m.paymentDelay)
                )
                .requiredOption(
                    ContractField.DELAY_TYPE,
                    (m) => m.delayType,
                    (m, v) => (m.delayType = v)
                )
                .requiredOption(
                    ContractField.PAYMENT_TYPE,
                    (m) => m.paymentType,
                    (m, v) => (m.paymentType = v)
                )
                .requiredOption(
                    ContractField.PAYMENT_METHOD,
                    (m) => m.paymentMethod,
                    (m, v) => (m.paymentMethod = v)
                )
                .number(
                    ContractField.TTN_DAYS,
                    (m) => m.ttnDays,
                    (m, v) => (m.ttnDays = v),
                    (m) => notNullablePositiveIntValidator(m.ttnDays)
                )
                .requiredDateTime(
                    ContractField.SIGNED,
                    (m) => m.signed,
                    (m, v) => (m.signed = v)
                )
                .text(
                    ContractField.CURRENCY,
                    (m) => m.currency,
                    (m, v) => (m.currency = v)
                )
                .text(
                    ContractField.REQUIREMENTS,
                    (m) => m.requirements,
                    (m, v) => (m.requirements = v)
                )
                .number(
                    ContractField.DOWNTIME_COST,
                    (m) => m.downtimeCost,
                    (m, v) => (m.downtimeCost = v),
                    (m) => nullableOrPositiveIntValidator(m.downtimeCost)
                )

                .requiredOption(
                    ContractField.COMPANY,
                    (m) => m.company,
                    (m, v) => (m.company = v)
                )
                .text(
                    ContractField.COMPANY_HEAD_POSITION,
                    (m) => m.companyHeadPosition,
                    (m, v) => (m.companyHeadPosition = v)
                )
                .text(
                    ContractField.COMPANY_HEAD_GENITIVE,
                    (m) => m.companyHeadGenitive,
                    (m, v) => (m.companyHeadGenitive = v)
                )
                .requiredText(
                    ContractField.COMPANY_BASIS,
                    (m) => m.companyBasis,
                    (m, v) => (m.companyBasis = v)
                )
                .option(
                    ContractField.COMPANY_ACCOUNT,
                    (m) => m.companyAccount,
                    (m, v) => (m.companyAccount = v),
                    (m) => accountValidator(m, m.companyAccount)
                )

                .requiredOption(
                    ContractField.CLIENT,
                    (m) => m.client,
                    (m, v) => (m.client = v)
                )
                .text(
                    ContractField.CLIENT_HEAD_POSITION,
                    (m) => m.clientHeadPosition,
                    (m, v) => (m.clientHeadPosition = v)
                )
                .text(
                    ContractField.CLIENT_HEAD_GENITIVE,
                    (m) => m.clientHeadGenitive,
                    (m, v) => (m.clientHeadGenitive = v)
                )
                .requiredText(
                    ContractField.CLIENT_BASIS,
                    (m) => m.clientBasis,
                    (m, v) => (m.clientBasis = v)
                )
                .option(
                    ContractField.CLIENT_ACCOUNT,
                    (m) => m.clientAccount,
                    (m, v) => (m.clientAccount = v),
                    (m) => accountValidator(m, m.clientAccount)
                )

                .list<AttachmentModel>(
                    ContractField.ATTACHMENTS,
                    (m) => m.attachments,
                    (m, v) => (m.attachments = v)
                )

                .load((id) =>
                    id && clientId
                        ? contracts.getForClient(id, clientId)
                        : Promise.resolve({} as ContractModel)
                )
                .submit(contracts.save)
                .idPathVariableName(PATH_VARIABLE_CLIENT_CONTRACT_ID)
                .redirectUrl(
                    generatePath(PATH_CP_CLIENT_CONTRACTS, {
                        clientId: util.stringOrEmpty(clientId),
                    })
                )
                .afterSubmit((_) => refresh())
                .build(),
        [refresh, clientId]
    );

const ClientContract: React.FC = () => {
    const {
        client: {id: clientId},
    } = useContext(ClientFormContext);
    const {refresh} = useRefreshContextAction();
    const formConfig = useFormConfig(refresh, clientId);
    return (
        <DwForm config={formConfig}>
            <ClientContractLayout/>
            <ContextError/>
        </DwForm>
    );
};

const ClientContractLayout: React.FC = () => {
    const {client} = useContext(ClientFormContext);
    const clientId = client ? client.id : null;
    const context = useContext(FormContext);
    const { state: { model }, setFieldValues } = context;
    const setValue = useSetFieldValue();

    const valueId = useFieldValue(ContractField.ID);
    const valueType = useFieldValue(ContractField.TYPE);
    const valueClientType = client?.type;

    const clientOption = useFieldValue(ContractField.CLIENT);
    useEffect(() => {
        if (client && !clientOption) {
            setFieldValues((m) => {
                    m[ContractField.CLIENT] = {
                        value: client.id,
                        label: client.name
                    };
                    m[ContractField.CLIENT_HEAD_POSITION] = client.headPosition;
                    m[ContractField.CLIENT_HEAD_GENITIVE] = client.headGenitive;
                    m[ContractField.CLIENT_BASIS] = client.basis?.label ?? '';
                });
        }
    }, [clientId, client, clientOption, setFieldValues]);

    const onChangeCompany = useCallback(
        (companyOption: SingleValue<Option>) => {
            if (companyOption) {
                companies.get(companyOption.value).then((c) => {
                    setValue(ContractField.COMPANY_HEAD_POSITION, c.headPosition);
                    setValue(ContractField.COMPANY_HEAD_GENITIVE, c.headGenitive);
                    setValue(ContractField.COMPANY_BASIS, c.basis?.label ?? '');
                    setValue(ContractField.COMPANY_ACCOUNT, null);
                });
            }
        },
        [setValue]
    );

    const renderClient = () => {
        return (
            <div className='client'>
                <Clients id={ContractField.CLIENT} clientTypes={[]} readOnly={true}/>
                <div>
                    В лице
                    <StringInput id={ContractField.CLIENT_HEAD_GENITIVE}/>
                </div>
                <div>
                    Должность
                    <StringInput id={ContractField.CLIENT_HEAD_POSITION}/>
                </div>
                <div>
                    Действует на основании
                    <StringInput id={ContractField.CLIENT_BASIS}/>
                </div>
                <div>
                    Расчетный счет контрагента
                    <ClientAccounts
                        id={ContractField.CLIENT_ACCOUNT}
                        clientId={model[ContractField.CLIENT]?.value}
                    />
                </div>
            </div>
        );
    };

    const renderCompany = () => {
        return (
            <div className='company'>
                <Companies
                    id={ContractField.COMPANY}
                    onChange={(newValue) => onChangeCompany(newValue)}
                />
                <div>
                    В лице
                    <StringInput id={ContractField.COMPANY_HEAD_GENITIVE}/>
                </div>
                <div>
                    Должность
                    <StringInput id={ContractField.COMPANY_HEAD_POSITION}/>
                </div>
                <div>
                    Действует на основании
                    <StringInput id={ContractField.COMPANY_BASIS}/>
                </div>
                <div>
                    Расчетный счет контрагента
                    <ClientAccounts
                        id={ContractField.COMPANY_ACCOUNT}
                        clientId={model[ContractField.COMPANY]?.value}
                    />
                </div>
            </div>
        );
    };

    const isClientContract = () => {
        const contractTypeId = valueType?.value;
        const clientTypeId = valueClientType?.value;
        return (
            contractTypeId &&
            CLIENT_CONTRACT_TYPES.includes(contractTypeId) &&
            clientTypeId &&
            [
                ClientType.CLIENT,
                ClientType.CLIENT_OR_CARRIER,
                ClientType.CLIENT_OR_SUPPLIER,
            ].includes(clientTypeId)
        );
    };

    const contractTypeFilter = (type: Option) => {
        const typeId = type.value;
        switch (client.type?.value) {
            case ClientType.CLIENT:
                return CLIENT_CONTRACT_TYPES.includes(typeId);
            case ClientType.CARRIER:
                return CARRIER_CONTRACT_TYPES.includes(typeId);
            case ClientType.CLIENT_OR_CARRIER:
                return CLIENT_OR_CARRIER_CONTRACT_TYPES.includes(typeId);
            case ClientType.SUPPLIER:
                return SUPPLIER_CONTRACT_TYPES.includes(typeId);
            case ClientType.CLIENT_OR_SUPPLIER:
                return CLIENT_OR_SUPPLIER_TYPES.includes(typeId);
        }
        return false;
    };

    const renderDownload = () => {
        switch (valueType?.value) {
            case ContractType.CARRIER:
                const onClick = (e: React.MouseEvent) => {
                    e.preventDefault();
                    docs.carrierContract(clientId!, valueId, FileType.PDF).then((blob) =>
                        fileUtil.downloadBlob(blob, `Договор_с_Перевозчиком_${valueId}.${FileType.PDF}`))
                }
                if (valueId && valueId > 0) {
                    return (
                        <button className='btn btn-sm btn-primary' onClick={onClick}>
                            Скачать в pdf
                        </button>
                    );
                }
                return <></>;
            default:
                return <></>;
        }
    };

    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row align-items-center'>
                        <div className='col-lg-3'>
                            Вид договора
                            <Options
                                id={ContractField.TYPE}
                                dictionaryType={DictionaryType.CONTRACT_TYPE}
                                filterCallback={contractTypeFilter}
                            />
                        </div>
                        <div className='col-lg-3'>
                            Номер договора
                            <StringInput id={ContractField.NUMBER}/>
                        </div>
                        <div className='col-lg-3'>
                            Дата договора
                            <DtPicker id={ContractField.SIGNED}/>
                        </div>
                        <div className='col-lg-3'>
                            <CheckboxInput id={ContractField.MAIN} label={'Основной'}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            Документы для оплаты
                            <StringInput id={ContractField.DOCUMENTS}/>
                        </div>
                        <div className='col-lg-3'>
                            Статус договора
                            <Options
                                id={ContractField.STATUS}
                                dictionaryType={DictionaryType.CONTRACT_STATUS}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            Отсрочка платежа, дней
                            <NumberInput id={ContractField.PAYMENT_DELAY}/>
                        </div>
                        <div className='col-lg-3'>
                            Тип дней отсрочки
                            <Options
                                id={ContractField.DELAY_TYPE}
                                dictionaryType={DictionaryType.DELAY_TYPE}
                            />
                        </div>
                        {CARRIER_CONTRACT_TYPES.includes(valueType?.value)
                            ? (
                                <div className='col-lg-3'>
                                    Стоимость простоя
                                    <NumberInput id={ContractField.DOWNTIME_COST}/>
                                </div>
                            ) : <></>
                        }
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            Вид оплаты
                            <Options
                                id={ContractField.PAYMENT_TYPE}
                                dictionaryType={DictionaryType.PAYMENT_TYPE}
                            />
                        </div>
                        <div className='col-lg-3'>
                            <CheckboxInput
                                id={ContractField.HAS_ADDITIONAL}
                                label={'Есть дополнительное соглашение'}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            Форма оплаты
                            <Options
                                id={ContractField.PAYMENT_METHOD}
                                dictionaryType={DictionaryType.PAYMENT_METHOD}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            Количество дней на возврат ТТН
                            <NumberInput id={ContractField.TTN_DAYS}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            Заказчик
                            {isClientContract() ? renderClient() : renderCompany()}
                        </div>
                        <div className='col-lg-6'>
                            Исполнитель
                            {isClientContract() ? renderCompany() : renderClient()}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    Валюта договора
                                    <StringInput id={ContractField.CURRENCY}/>
                                </div>
                                <div className='col-lg-4'>
                                    Действует с
                                    <DtPicker id={ContractField.BEGIN_DATE}/>
                                </div>
                                <div className='col-lg-4'>
                                    Действует до
                                    <DtPicker id={ContractField.END_DATE}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <AttachmentsArea
                                        id={ContractField.ATTACHMENTS}
                                        attachmentType={AttachmentType.CONTRACT}
                                        parentId={valueId}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            Требования по договору для импорта в договор-заявку
                            <TextEditor id={ContractField.REQUIREMENTS}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-4 text-left'></div>
                <div className='col-lg-4 text-center'>
                    <SaveAndCancelButtonGroup />
                </div>
                <div className='col-lg-4 text-right'>
                    {renderDownload()}
                </div>
            </div>
        </div>
    );
};

export default ClientContract;
