import { deleteRequest, get, post } from './rest';
import { ApiResponse } from './apiResponse';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { PalletPassportTemplateListModel, PalletPassportTemplateModel } from '../model/palletPassportTemplate/palletPassportTemplateModel';
import { BaseOptionRequest } from './baseOptionRequest';
import { Option } from '../component/control/option';

const PATH_BASE = 'pallet-passport-template';

const palletPassportTemplates = {
    get: (id: number) => get<PalletPassportTemplateModel>(`${PATH_BASE}/${id}`),
    save: (model: PalletPassportTemplateModel) => post<ApiResponse>(`${PATH_BASE}`, model),
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),
    list: (request: ListRequest) => post<PagedResponse<PalletPassportTemplateListModel>>(`${PATH_BASE}/list`, request),
    allOptions: (request: BaseOptionRequest) => post<Option[]>(`${PATH_BASE}/all-options`, request),
};

export default palletPassportTemplates;