import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import DwFormTabs from '../form/DwFormTabs';
import {
    PATH_RUN,
    PATH_RUN_ATTACHMENTS,
    PATH_RUN_HISTORY,
    PATH_RUN_MAP,
    PATH_RUN_TALMANS,
    PATH_VARIABLE_RUN_ID,
    SEARCH_PARAM_SOURCE_ID,
} from '../../routerPaths';
import util from '../../common/util';
import FormSkeleton from '../form/FormSkeleton';
import runs from '../../api/runs';
import { RunModel } from './model/runModel';
import { useFormVersion } from '../form/DwForm';
import { TableRefreshWrapper } from '../table/TableRefreshWrapper';
import companies from '../../api/companies';
import { LocationStateRun } from './Run';

type SharedRunContext = {
    run: RunModel;
};

export const RunFormContext = React.createContext<SharedRunContext>(
    {} as SharedRunContext
);

const RunForm: React.FC = () => {
    const [run, setRun] = useState<RunModel>({} as RunModel);
    const [showLoading, setShowLoading] = useState(true);
    const runId = util.toNumber(useParams()[PATH_VARIABLE_RUN_ID]!)!;
    const {formVersion} = useFormVersion();
    const [searchParams] = useSearchParams(),
        sourceId = util.toNumber(searchParams.get(SEARCH_PARAM_SOURCE_ID) ?? '');
    const location = useLocation();
    const { quoteOption } = useMemo(() => (location.state || {}) as LocationStateRun, [location.state]);

    useEffect(() => {
        let shouldUpdate = true;
        if (runId > 0) {
            runs.get(Number(runId)).then((run) => shouldUpdate && setRun(run));
        } else if (sourceId && sourceId > 0) {
            runs.get(sourceId)
                .then((model: RunModel) => {
                    setShowLoading(false);
                    shouldUpdate && setRun({
                        ...model,
                        id: null as any,
                        route: null as any,
                        clientPlannedPaymentDate: null as any,
                        carrierPlannedPaymentDate: null as any,
                        created: null as any,
                        number: null as any,
                    })
                });
        } else if (quoteOption) {
            if (quoteOption.value > 0) {
                setRun({} as RunModel);
            }
        } else {
            companies.getCurrent().then(defaultCompany => {
                setShowLoading(false);
                setRun({
                    clientCompany: defaultCompany,
                    carrierCompany: defaultCompany
                } as unknown as RunModel);
            });
        }
        return () => {
            shouldUpdate = false;
        }
    }, [runId, sourceId, quoteOption, formVersion]);
    const isNew = run?.id == null || run?.id < 1;
    const runIdPath = util.stringOrEmpty(runId)!;
    const runContext = useMemo(() => ({run}), [run]);
    if (showLoading && runId > 0 && !run.id) {
        return (
            <div className='form-wrapper d-flex flex-column flex-sm-fill'>
                <div>
                    <FormSkeleton className={'col-1 mr-2'}/>
                    <FormSkeleton className={'col-1 mr-2'}/>
                    <FormSkeleton className={'col-1 mr-2'}/>
                    <FormSkeleton className={'col-1 mr-2'}/>
                    <FormSkeleton className={'col-1'}/>
                </div>
                <div className='d-flex h-100 mt-2'>
                    <FormSkeleton className={'card-body'}/>
                </div>
            </div>
        );
    }
    return (
        <RunFormContext.Provider value={runContext}>
            <TableRefreshWrapper>
                <div className='form-wrapper'>
                    <DwFormTabs
                        items={[
                            {
                                name: isNew ? 'Новый рейс' : `Рейс #${run?.id}`,
                                path: generatePath(PATH_RUN, { [PATH_VARIABLE_RUN_ID]: runIdPath }),
                                end: true,
                            },
                            {
                                name: 'Карта',
                                path: generatePath(PATH_RUN_MAP, { [PATH_VARIABLE_RUN_ID]: runIdPath }),
                                disabled: isNew || run.route?.length === 0,
                            },
                            {
                                name: 'Документы',
                                path: generatePath(PATH_RUN_ATTACHMENTS, { [PATH_VARIABLE_RUN_ID]: runIdPath }),
                                disabled: isNew,
                            },
                            {
                                name: 'Тальманская расписка',
                                path: generatePath(PATH_RUN_TALMANS, { [PATH_VARIABLE_RUN_ID]: runIdPath }),
                                disabled: isNew,
                            },
                            {
                                name: 'История',
                                path: generatePath(PATH_RUN_HISTORY, { [PATH_VARIABLE_RUN_ID]: runIdPath }),
                                disabled: isNew,
                                end: true,
                            },
                        ]}
                    />
                    <Outlet/>
                </div>
            </TableRefreshWrapper>
        </RunFormContext.Provider>
    );
};

export default RunForm;
