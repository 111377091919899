import { useContext, useMemo } from 'react';
import { ClientFormContext } from '../ClientForm';
import { PalletPassportTemplateSelect } from './PalletPassportTemplateSelect';
import { DwForm } from '../../../form/DwForm';
import ContextError from '../../../form/ContextError';
import { FormConfig } from '../../../form/formConfig';
import clients from '../../../../api/clients';
import { PATH_CP_CLIENT_SETTINGS, PATH_VARIABLE_CLIENT_ID } from '../../../../routerPaths';
import util from '../../../../common/util';
import { generatePath } from 'react-router-dom';
import { ClientSettingsModel } from '../../../../model/client/clientSettingsModel';
import { ClientSettingsField } from './clientSettingsField';
import { SaveButton } from '../../../form/SaveButtonGroup';
import { ToastMessage } from '../../settings/const';
import { showSuccessToast } from '../../../control/showToast';

const useFormConfig = (clientId: number) =>
    useMemo(
        () =>
            FormConfig.builder<ClientSettingsModel>()                                
                .option(
                    ClientSettingsField.PALLET_PASSPORT_TEMPLATE,
                    (m) => m.palletPassportTemplate,
                    (m, v) => (m.palletPassportTemplate = v)
                )
                .load(clients.getSettings)
                .submit((model) => clients.saveSettings(clientId, model))
                .afterSubmit(() => showSuccessToast(ToastMessage.SETTINGS_SAVED))
                .idPathVariableName(PATH_VARIABLE_CLIENT_ID)
                .redirectUrl(
                    generatePath(PATH_CP_CLIENT_SETTINGS, {
                        clientId: util.stringOrEmpty(clientId),
                    })
                )
                .build(),
        [clientId]
    );

const ClientSettings: React.FC = () => {
    const { client: { id: clientId } } = useContext(ClientFormContext);
    const formConfig = useFormConfig(clientId);

    return (
        <DwForm config={formConfig}>
            <>
                <div className='card mb-3'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-4'>
                                Печатная форма паспорта паллет
                                <PalletPassportTemplateSelect id={ClientSettingsField.PALLET_PASSPORT_TEMPLATE} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='text-center'>
                    <SaveButton/>
                </div>
            </>
            <ContextError/>
        </DwForm>
    );
};

export default ClientSettings;